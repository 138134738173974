.main_container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    text-align: center;
}
.main_container .msg{
    font-size: 24px;
    font-weight: 400;
}