.iconColor {
  color: blue !important;
  /* border: 2px solid yellow !important; */
}

.text_cap{
  text-transform: capitalize !important;
}

.overflow_list{
  overflow: auto !important;
  height: 70vh !important;
}

.main{
  *width: 100% !important;
  position: relative !important;
  
}
.close_icon{
  color: red !important;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 15px !important;
  cursor: pointer;
}

.img_popup{
  width: 40% !important;
  *height: 60vh !important;
  *background: green;
  position: absolute;
  left: 30% !important;
  top: 20vh !important;
}

.pointer{
  cursor: pointer;
}
.reject{
  color: red;
  font-weight: bold;
}
.accept{
  color: blueviolet;
  font-weight: bold;
}