@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css);
@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css);
@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css);
@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css);
@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css);
@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css);
@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css);
@import url(//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css);
.Dashboard_topCardLeft__Q0E0w {
  float: left;
}
.Dashboard_viewstyle__2OB9i {
  border-radius: 40px;
  border: 2px solid #4a1d75;
  padding: 5px;
  background-color: #4a1d75 !important;
  /* margin-left: 2px; */
  color: #fff !important;
  text-decoration: none !important;
  /* width: 20vw; */
}

.Dashboard_dashimg__3wE7i {
  /* width: 30vh;
  height: 100vh;
  overflow: hidden; */
  background-image: url(../../static/media/group3.e7c1db50.png);
  background-size: cover;
  color: white;
  text-align: center;
  /* width:px; */
  background-repeat: no-repeat;
  background-position: center;
  /* width: 100%; */
}
.Dashboard_dashtext__1PJmv {
  /* padding: 300px 0; */
  /* padding-top: 0px; */
  padding-top: 200px;
  padding-bottom: 350px;
}
@media only screen and (max-width: 1300px) {
  .Dashboard_dashtext__1PJmv {
    /* padding: 20px 0!important; */
    /* padding-top: 0px; */
    padding-top: 148px !important;
    padding-bottom: 248px !important;
  }
  .Dashboard_text__1rFV0 {
    color: #ffffff;
    font: inter;
    font-weight: 500;
    font-size: 18px !important;
    letter-spacing: 2px;
    /* color: red; */
    /* border:2px solid red!important */
    padding-top: 4px !important;
  }
  .Dashboard_text1__mpIDV {
    color: #fff;
    font: lato;

    letter-spacing: normal;
    /* font-size: 10px!important; */
    /* border:2px solid red!important */
  }
  /* .learnMedia{
  top: 50%;
}; */
  .Dashboard_learn__3qng0 {
    font-size: 100px !important;
    font-weight: 500;
    letter-spacing: 1px;
  }
  .Dashboard_in__2z2mk {
    font-size: 100px !important;
    color: #ffffff;
    letter-spacing: normal;
    /* font-size: 10px!important */
  }
  .Dashboard_new__3yQyB {
    font-size: 100px !important;
    color: #ffffff;
    font-family: lato;
    letter-spacing: normal;
  }
  .Dashboard_text2__3yiD0 {
    color: #fff;
    font-family: inter;
    /* letter-spacing: 15px; */
    font-size: 17px !important;
    /* border:2px solid red!important */
  }
  .Dashboard_course__1yC1O {
    color: #fff;
    font-family: inter;
    font-size: 17px !important;
  }
}
.Dashboard_text__1rFV0 {
  color: #ffffff;
  font: inter;
  font-weight: 400;
  font-size: 25px;
  letter-spacing: 2px;
  /* border:2px solid green */
  /* padding-bottom: 20px; */
}
.Dashboard_text1__mpIDV {
  color: #fff;
  font: lato;

  letter-spacing: normal;
  /* border:2px solid green */
}
.Dashboard_learn__3qng0 {
  color: #c5f9d5;
  font-size: 140px;
  font-weight: 500;
  letter-spacing: 0;
}
.Dashboard_in__2z2mk {
  font-size: 140px;
  color: #ffffff;
  letter-spacing: normal;
}
.Dashboard_new__3yQyB {
  font-size: 140px;
  color: #ffffff;
  font-family: lato;
  letter-spacing: normal;
}
.Dashboard_text2__3yiD0 {
  color: #fff;
  font-family: inter;
  font-size: 24px;
  /* border:2px solid green */
}
.Dashboard_course__1yC1O {
  color: #fff;
  font-family: inter;
  font-size: 24px;
}
.Dashboard_buymore__14HbB {
  padding: 5px;
  font-size: 10px;
  height: 30px;
}
.Dashboard_topCardLeft__Q0E0w h5 {
  font-size: 14px;
  margin-bottom: 12px;
  text-align: left;
  color: #9c9c9c;
  font-weight: 400;
}

.Dashboard_topCardLeft__Q0E0w h2 {
  font-size: 17px;
  margin-bottom: 10px;
  margin-top: 0;
}

.Dashboard_topCardLeft__Q0E0w span {
  font-size: 12px;
  background: #222648;
  padding: 5px 10px;
  border-radius: 10px;
  color: #fff;
  letter-spacing: 1px;
}

.Dashboard_topCardRight__3mM12 {
  float: right;
  padding: 15px 0;
  padding-bottom: 0px;
}
.Dashboard_carding__SyUxE {
  width: 18vw;
  height: auto;
  padding: 2px;
  background-color: #c5f9d5;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.Dashboard_assignmentDiv1__1ZKtp {
  padding: 1px !important;
  background-color: #aa96db !important;
  /* border-radius: 8px!important; */
  text-align: center;
  width: 18vw;
  height: 12vh;
  justify-content: center;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.Dashboard_sessionsDiv__1FQGK {
  /* padding:1px!important; */
  background-color: #ffffd3 !important;
  /* background-color: #C5F9D5!important; */
  /* border-radius:0 0 8px 8px!important; */
  text-align: center;
  width: 18vw;
  height: 25vh;
  justify-content: center;
  color: black;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.Dashboard_sessionbutton__3lMGj {
  background-color: #ab94e2 !important;
  border-radius: 40px !important;
  width: 80px !important;
  height: 30px !important;
}
.Dashboard_topCardRight__3mM12 img {
  width: 58px;
}

.Dashboard_assignmentDiv__3z3jz h5,
.Dashboard_queriesDiv__2n5BF h5 {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-bottom: 5px;
}

.Dashboard_assignmentDiv__3z3jz p {
  display: inline-block;
  font-size: 14px;
  color: #686f7a;
  margin-bottom: 0;
  margin-top: 3px;
}

.Dashboard_queriesDiv__2n5BF p {
  display: inline-block;
  font-size: 14px;
  color: #686f7a;
  margin-bottom: 0;
  line-height: 27px;
}

.Dashboard_hr__1wT9G {
  margin-top: 10px;
  margin-bottom: 10px;
}

.Dashboard_curriculum__fstRY {
  margin-right: 10px;
  font-size: 15px;
}

.Dashboard_subject__ScQ0A,
.Dashboard_chapter__zBUtX {
  float: left;
  margin-right: 10px;
  font-size: 15px;
}

.Dashboard_queryDiv__3nMWv {
  /* line-height: 28px; */
  padding: 10px !important;
  background-color: #c5f9d5 !important;
  border-radius: 8px !important;
  text-align: center;
  width: 18vw;
  /* height: 18vh; */
  margin-bottom: 20px;
  height: auto;
  /* margin-bottom: 10px; */
}

/* .queryDiv .subject {
  float: none;
  margin-right: 10px;
  font-size: 14px;
  width: 100%;
} */

/* .queryDiv .topic {
  float: none;
  margin-right: 10px;
  font-size: 14px;
} */

/* .queryDiv .chapter {
  margin-right: 10px;
  font-size: 14px;
} */

.Dashboard_sessionsDiv__1FQGK h2 {
  font-size: 15px;
  margin-bottom: 5px;
  margin-top: 0;
  color: #333;
}

.Dashboard_sessionsDiv__1FQGK p {
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 0;
}

.Dashboard_sessionsDiv__1FQGK span {
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 10px;
  color: #fff;
  letter-spacing: 1px;
}

.Dashboard_sessionsDiv__1FQGK button {
  margin-top: 12px;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.16), 0 0px 0px 0 rgba(0, 0, 0, 0.12);
  background-color: #ea9f07;
  border-color: #ea9f07;
  width: 100%;
  padding: 5px;
  border-radius: 3px;
}
.Dashboard_studentDash__37Qtl {
  display: flex;
  justify-content: space-between;
  width: 96% !important;
}
.Dashboard_studentDash1__1jBWW {
  display: flex;
  justify-content: space-between;
  width: 95% !important;
}
.Dashboard_studentdashCurrentCurriculum__3GTkF {
  display: flex;
  justify-content: space-between;
  width: 96% !important;
  margin-bottom: 10px;
}
.Dashboard_row1__10Ced {
  display: flex !important;
  /* width: 100%; */
  /* justify-content: space-around; */
  /* flex-wrap: wrap; */
  /* padding: 20px; */
  /* margin-right: 60px; */
  overflow: scroll !important;
  /* -ms-overflow-style: none;
  scrollbar-width: none; */
  width: 100%;
}
.Dashboard_row1__10Ced::-webkit-scrollbar {
  height: 0px !important;
  width: 0px !important;
  border: 1px solid #d5d5d5 !important;
}

.Dashboard_main_box__2ftqd {
  background: #c5f9d5;
  min-width: 380px;
  margin-right: 20px;
  border-radius: 30px;
}

.Dashboard_box_img__1Rf9b {
  /* margin: 0 auto !important; */
  height: 60px;
  width: 60px;
}

.Dashboard_box_img1__rhhUT {
  width: 90px;
  margin-top: -10px;
}
.Dashboard_box_img2__-9QMu {
  width: 72px;
  margin-top: -10px;
}

.Dashboard_heading__389Al {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.Dashboard_degname__trIKx {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}

.Dashboard_headingT__1AtLF {
  font-size: 25px;
  font-weight: 600;
  color: #20639b;
}

.Dashboard_code__3tkHI {
  color: #20639b;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}

.Dashboard_datebox__33mRE {
  background: #4a1d75;
  color: white;
  border-radius: 30px;
  padding: 10px 10px;
}

.share_cardDiv__3X41T {
 /* before */
  /* background: rgb(255, 255, 255); */
  /* after */
  background: #F5F5F5;

  width: 100%;
  border-radius: 6px;
  border: 2px solid rgb(238, 238, 238);
  padding: 15px;
  padding-top: 30px;
  text-align: center;
  /* align-items: center;
  justify-content: center;
  display: flex;
  font-size: 15px; */
}

.share_center__ACrPS {
  font-weight: 400;
  margin-top: 3px;
}

.share_modalContent__3OtDt {
  background: transparent !important;
  border: 0px !important;
}

.share_modalBackdrop__1yOO6 {
  opacity: 0.7 !important;
}

.MyQueries_queryDiv__3w82S p {
  display: inline-block;
  font-size: 14px;
  color: black;
  margin-bottom: 0;
  line-height: 27px;
}
.MyQueries_selectstyle__1zOCh{
  border-radius: 40px;
}
.MyQueries_raisequerymain__3kZSA{
  padding-top: 40px;
}

.MyQueries_dashimg__3Fv8H {
  background-image: url(../../static/media/group3.e7c1db50.png);
  /* height: 70vh; */
  overflow: hidden;
  padding-top: 340px;
  padding-bottom: 210px;
    background-repeat: no-repeat;
  background-position: center;
    background-size: cover;
  /* padding-bottom: 300px; */
}
.MyQueries_butonstyle__1T5kI{
  /* padding: 0 20px; */
  display: flex;
  justify-content: space-between;
  width: 40%;
  
}
.MyQueries_buttonTab__3Tm99{
  width: 200px !important;
  text-align: center;
  /* border-radius: 40px; */
  border-radius: 30px !important;
  padding-top: 8px !important;
  /* font-size: 30px; */
  /* border: 2px solid green; */
}
.MyQueries_queryTypeButton__1__a3{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.MyQueries_hr__14EpN {
  margin-top: 15px;
  margin-bottom: 10px;
  clear: both;
  /* width:100%; */
}
/* .cardinput{
  width: 100%;
} */

.MyQueries_curriculum___36g1{
   margin-left: 10px;
}
.MyQueries_subject__3Wmkx{
   margin-left: 10px;
}
.MyQueries_topic__2lAfM{
  margin-left: 10px;
}
.MyQueries_chapter__28xBp {
  float: left;
  margin-right: 10px;
  margin-top: 8px;
  font-size: 16px;
  /* line-height: 20px; */
   margin-left: 10px;
}

.MyQueries_main__2rVXY {
  width: 94vw;
  border-radius: 6px;
  /* border: 1px solid black; */
  margin-top: 25px;




}

.MyQueries_queryFeatures__hO1cP {
  /* background: #e9edf6; */
  background: #e9eaec;
  margin-top: 10px;
  padding: 10px;
  overflow: hidden;
}

.MyQueries_queryFeatures__hO1cP span {
  cursor: pointer;
  padding: 4px 10px;
  margin-left: 10px;
  border-radius: 2px;
  float: right;
  color: #fff;
  font-size: 13px;
  letter-spacing: 0.5px;
}

.MyQueries_queryFeatures__hO1cP .MyQueries_replay__3ht7V {
  background: #AA96DB;
}

.MyQueries_queryFeatures__hO1cP .MyQueries_resolve__32HgR {
  background: #66bb6a;
}

.MyQueries_queryFeatures__hO1cP .MyQueries_feedback__34zg9 {
  background: #42a5f5;
}

.MyQueries_raiseQueryDiv__1OSBs select {
  height: 42px;
}

.MyQueries_noReplay__2lUpL {
  color: #000 !important;
  font-size: 14px !important;
  font-weight: 500;
}

.MyQueries_studentReview__3y3WL {
  background: #e6f3fd;
  padding: 10px;
  border-radius: 5px;
  width: 70%;
  margin: 10px 14px;
}

.MyQueries_teacherReview__2VgV8 {
  background: #fff2e8;
  padding: 10px;
  border-radius: 5px;
  width: 70%;
  margin: 10px 0px;
}

.MyQueries_messagesDiv__29QwO {
  max-height: 250px;
  overflow-y: auto;
}

/* width */
.MyQueries_queryDiv__3w82S ::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.MyQueries_queryDiv__3w82S ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px #ccc;
  border-radius: 10px;
}

/* Handle */
.MyQueries_queryDiv__3w82S ::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 10px;
}

/* Handle on hover */
.MyQueries_queryDiv__3w82S ::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

.MyQueries_open__1m0qe {
  display: block;
}

.MyQueries_close__3iJjc {
  display: none;
}

.MyQueries_cardDiv2__QbhQo {
  background-color: #ecf3ff;
  Width: 1170px;
  Height: 244px;
  Top: 437px;
  Left: 67px;
  Radius: 6px;
  margin: 15px;
  border-radius: 6px;
}

.MyQueries_cardDiv3__1Xt5w {
  background-color: #ECF3FF;
  height: 190px;
  border-radius: 6px;
}








@media screen and (min-width: 280px) {
  .MyQueries_cardDiv3__1Xt5w {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 19%;
  }
}
@media screen and (min-width: 280px) {

  .MyQueries_curriculum___36g1,
  .MyQueries_subject__3Wmkx,
  .MyQueries_topic__2lAfM,
  .MyQueries_chapter__28xBp {
    float: left;
    margin-right: 3px;
    margin-top: 8px;
    font-size: 9px;
    /* line-height: 20px; */
  }
}


@media screen and (min-width: 360px) {
  .MyQueries_cardDiv3__1Xt5w {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 25%;
  }
}

@media screen and (min-width: 360px) {

  .MyQueries_curriculum___36g1,
  .MyQueries_subject__3Wmkx,
  .MyQueries_topic__2lAfM,
  .MyQueries_chapter__28xBp {
    float: left;
    margin-right: 1px;
    margin-top: 8px;
    font-size: 12px;
    /* line-height: 20px; */
  }
}

@media screen and (min-width: 375px) {
  .MyQueries_cardDiv3__1Xt5w {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 26%;
  }
}

@media screen and (min-width: 375px) {

  .MyQueries_curriculum___36g1,
  .MyQueries_subject__3Wmkx,
  .MyQueries_topic__2lAfM,
  .MyQueries_chapter__28xBp {
    float: left;
    margin-right: 1px;
    margin-top: 8px;
    font-size: 13px;
    /* line-height: 20px; */
  }
}
@media screen and (min-width: 412px) {
  .MyQueries_cardDiv3__1Xt5w {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 29%;
  }
}
@media screen and (min-width: 412px) {

  .MyQueries_curriculum___36g1,
  .MyQueries_subject__3Wmkx,
  .MyQueries_topic__2lAfM,
  .MyQueries_chapter__28xBp {
    float: left;
    margin-right: -1px;
    margin-top: 8px;
    font-size: 14px;
    /* line-height: 20px; */
  }
} 
@media screen and (min-width: 540px) {
  .MyQueries_cardDiv3__1Xt5w {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 40%;
  }
}
@media screen and (min-width: 768px) {
  .MyQueries_cardDiv3__1Xt5w {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 58%;
  }
}

@media screen and (min-width: 820px) {
  .MyQueries_cardDiv3__1Xt5w {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 62%;
  }
}

@media screen and (min-width: 1024px) {
  .MyQueries_cardDiv3__1Xt5w {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 79%;
  }
}

@media screen and (min-width: 912px) {
  .MyQueries_cardDiv3__1Xt5w {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 70%;
  }
}

@media screen and (min-width: 1280px) {
  .MyQueries_cardDiv3__1Xt5w {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 100%;
  }
}
.Assignments_dashimgstyle__2ZQog{
   background-image: url(../../static/media/group3.e7c1db50.png);
     overflow: hidden;
  padding-top: 360px;
  padding-bottom: 210px;
    background-repeat: no-repeat;
  background-position: center;
    background-size: cover;
}
@media only screen and (max-width: 1280px){
  .Assignments_dashimg__2sabk{
   /* background-image: url("../../../assets/Banners/group3.png"); */
     /* overflow: hidden; */
 /* padding-top: 100px; */
  /* padding-bottom: 200px; */
    background-repeat: no-repeat;
  background-position: center;
    background-size: cover;
}

}
.Assignments_assignmentDiv__Ty8xD h5 {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-bottom: 5px;
  cursor: pointer;
}
/* .theam-color{
  background: #222648 !important;
} */
.Assignments_dashimg__2sabk {
  height: 60vh;
  overflow: hidden;
}

.Assignments_assignmentDiv__Ty8xD p {
  display: inline-block;
  font-size: 14px;
  color: #686f7a;
  margin-bottom: 0;
  margin-top: 3px;
}

.Assignments_assignment-div__384IQ span {
  color: #fff;
  width: 100%;
  display: block;
  padding: 1px;
  margin-top: 5px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.Assignments_assignment-div__384IQ span.Assignments_success__1WRzb {
  background: green;
}

.Assignments_assignment-div__384IQ span.Assignments_fail__3bUNb {
  background: red;
}

.Assignments_test-summary__34mgJ .Assignments_badge__1y-Rv {
  float: right;
}

.Assignments_testSummary__3UE51 {
  background: #fff;
  width: 100%;
  float: left;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #efefef;
  box-shadow: none;
  transition: all 0.2s ease-in-out;
}

.Assignments_testSummary__3UE51 p {
  display: block;
  padding: 12px 10px;
  text-align: left;
  border-bottom: 1px dashed #efefef;
  margin: 0px;
}

.Assignments_testSummary__3UE51 p:last-child {
  padding-bottom: 5px;
  border-bottom: 0;
}

.Assignments_testSummary__3UE51 .Assignments_badge__1y-Rv {
  float: right;
  padding: 6px 8px;
  font-weight: 400;
  font-size: 13px;
  width: 30px;
}

.Assignments_assignmentResult__1vFnX {
  display: block;
  background: transparent;
  margin-top: 8px;
  padding: 3px 10px;
  color: #fff;
  border-radius: 3px;
  text-align: center;
}

.Assignments_assignmentPass__3y02I {
  background: #4caf50;
}

.Assignments_assignmentPassAvg__2r6X2 {
  background: #ffbf00;
}

.Assignments_assignmentFail__1CNkg {
  background: #f71b1b;
}

.Assignments_assignmentPreviewDiv__3ajWZ {
  background: #fff;
  float: left;
  width: 100%;
  border-radius: 6px;
  border: 2px solid #eee;
  padding: 15px;
  box-shadow: 0 0 11px -6px rgb(187 187 187 / 65%);
  font-size: 14px;
  overflow: hidden;
}

.Assignments_assignmentPreviewDiv__3ajWZ table th {
  border: 0px;
}

.Assignments_assignmentPreviewDiv__3ajWZ table td {
  border: 0px;
}

.Assignments_assignmentPreviewDiv__3ajWZ h2 {
  font-size: 22px;
  margin-bottom: 17px;
  font-weight: 600;
  color: #333;
  text-align: center;
}

.Assignments_testBack__3PLOX {
  position: absolute;
  right: 0px;
}
.Assignments_testBack1__uMvbL{
   position: absolute;
    /* right: ; */
    /* margin-left: 200px; */
}

.Assignments_testStart__2oRvX {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.Assignments_testTimerBg__36Tkt {
  background: #fff;
  width: 100%;
  float: left;
  padding: 0;
  border-radius: 10px;
  margin-bottom: 50px;
  border: 1px solid #efefef;
  transition: all 0.2s ease-in-out;
}

.Assignments_testTimerLeft__2d9tw {
  width: 100%;
  float: left;
  display: flex;
  margin-bottom: 0px;
}

.Assignments_testTimerLeft__2d9tw li {
  width: 50%;
  display: inline-block;
  padding: 30px 20px;
  border-right: 1px solid #efefef;
  text-align: center;
}

.Assignments_testTimerLeft__2d9tw h4 {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin-bottom: 15px;
  text-align: center;
  line-height: 26px;
  height: 100px;
  width: 100px;
  display: inline-block;
  border: 2px solid #333;
  border-radius: 100%;
  padding: 35px 0;
  background: #fff;
}

.Assignments_quesTitle__N4Rl7 {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  text-align: left;
  line-height: 28px;
  margin-top: 0;
  /* margin-bottom: 14px; */
}

.Assignments_quesTitle__N4Rl7 span {
  font-weight: 600;
  margin-right: 10px;
  float: left;
}

.Assignments_testInfo__2VYDx {
  /* text-align: right; */
  padding: 8px;
  box-shadow: 0 0.052083333in 11.25pt -9px rgb(0 0 0 / 10%);
  border-bottom: 1px solid #e6e6e6;
}
.Assignments_testInfo1__2-Cb3 {
  text-align: right;
  /* padding: 8px; */
  /* box-shadow: 0 0.052083333in 11.25pt -9px rgb(0 0 0 / 10%); */
  /* border-bottom: 1px solid #e6e6e6; */
}
.Assignments_testInfo12__1Fv2f {
  text-align: right;
  padding-top: 3%;
}
.Assignments_ruleAssessment__2HRh-{
   padding-left: 3%;
   padding-top: 2%;
}
.Assignments_startAssessment__hr4oa{
  padding-left: 4%;
}
.Assignments_imageAnswer__1G1px{
  width: 350px;
  height:200px;
}
.Assignments_testInfo__2VYDx h3 {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin-top: 5px;
  margin-bottom: 5px;
}

.Assignments_radioCheckbox__2VoKx input[type="radio"] {
  margin-right: 7px;
  position: relative;
  top: 1px;
}

.Assignments_quesItem__BsWqj {
  margin-bottom: 30px;
  box-shadow: 0px 1px 13px 0px rgb(198 198 198 / 30%);
  border: 1px solid rgba(51, 122, 183, 0.21);
  padding: 10px;
  background: #fff;
}

.Assignments_backLink__lA3J1 {
  color: #333;
  float: right;
  height: 38px;
  background: #f1f1f1;
  border: 1px solid #d0d0d0;
  border-radius: 20px;
  padding: 8px 20px;
  font-size: 14px;
  margin-top: 10px;
}

.Assignments_backLink__lA3J1 a {
  text-decoration: none;
  color: #000;
}

.Assignments_testResultLeft__2e49D {
  text-align: center;
}

.Assignments_testResultLeft__2e49D li {
  width: 150px;
  display: inline-block;
  padding: 0;
}

.Assignments_resultDt__3PqLo {
  float: left;
  width: 100%;
  text-align: center;
}

.Assignments_resultDt__3PqLo span {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 10px;
  text-align: center;
  line-height: 50px;
  height: 55px;
  width: 55px;
  display: inline-block;
  border-radius: 100%;
}

.Assignments_resultDt__3PqLo p {
  font-weight: 500;
  font-size: 16px;
}

.Assignments_rightAns__3zRPu {
  background: #01b200;
}

.Assignments_wrongAns__32Yla {
  background: #fb3838;
}

.Assignments_totQues__2_5by {
  background: #57a4ff;
}
.Assignments_my-component__9Y8LQ {
  background: #fff;
}

.Assignments_fullscreen-enabled__FDkS5 .Assignments_my-component__9Y8LQ {
  background: #000;
}

.AssignmentType_imgDiv__2REys{
    /* background-color: rgb(0, 0, 0); */
    /* width: 100%; */
    /* height: auto; */
    /* opacity: 0.7; */
    /* filter: brightness(30%); */

}

.Curriculum_imagetheme__3s59A{
  background-image: url(../../static/media/group3.e7c1db50.png);
   overflow: hidden;
  padding-top: 360px;
  padding-bottom: 210px;
    background-repeat: no-repeat;
  background-position: center;
    background-size: cover;
}
@media only screen and (max-width: 1280px){
  .Curriculum_imagetheme__3s59A{
  background-image: url(../../static/media/group3.e7c1db50.png);
   overflow: hidden;
  /* padding-top: 190px;
  padding-bottom: 210px; */
    background-repeat: no-repeat;
  background-position: center;
    background-size: cover;
}
}
/* .selectinput{
  color: black;
} */
.Curriculum_progress__1YWWf {
  height: 10px;
  border: 1px solid #f1f1f1;
  background: #e0e0e0;
  border-radius: 10px;
}
.Curriculum_dashimg__3t4tc {
  height: 60vh;
  overflow: hidden;
}

.Curriculum_progressBar__jt5ry {
  height: 8px;
  border-radius: 10px;
}

.Curriculum_chaptersDiv__1e4Cd table {
  background: #fff;
}

.Curriculum_chaptersDiv__1e4Cd table td:nth-child(2) {
  font-weight: 500;
  width: 200px;
}

.Curriculum_chaptersDiv__1e4Cd table td:nth-child(3) {
  width: 40%;
}

.Curriculum_modalBody__3Oo7c {
  min-height: 400px;
}

.Curriculum_notAvailable__2VH46 {
  text-align: center;
  width: 100%;
  padding-top: 150px;
  font-size: 18px;
  font-weight: 500;
}
.Curriculum_txt__3azb5 {
  display: flex;
  /* position: absolute; */
  align-items: center;
  width: 100%;
  height: 80px;
  left: 63px;
  top: 437px;
  font-size: 1.7rem !important;
  background: rgb(245, 240, 240);
  border-radius: 3px;
  padding: 0px 20px;
}
.Curriculum_area__1JI1J{
  display: flex;
  /* position: absolute; */
  align-items: center;
  width: 100%;
  height: 360px;
  left: 63px;
  top: 437px;
  font-size: 1.7rem !important;
  background: rgb(245, 240, 240);
  border-radius: 3px;
  padding: 0px 20px;
}
.Curriculum_area__1JI1J p{
  font-size: 12px;
}
.Curriculum_area__1JI1J input{
  font-size: 12px;
}
.Curriculum_txt__3azb5 p{
  font-size: 14px;
}

.Curriculum_box__2Bc0h {
  /* box-sizing: border-box; */

  /* position: absolute; */
  width: auto;
  height: auto;
  left: 34px;
  top: 341px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 20px;
  margin-bottom: 20px;
  border-radius: 6px;
}


/* ============================================================ */
.Curriculum_curri_headings__GlCqY{
  font-size: 2.3rem !important;
    font-weight: 600;
    margin-bottom: 20px !important;
}
.Curriculum_banner__RLSvq{
  width: 100%;
}
.Curriculum_relative__WgBZb{
  position: relative !important;
}

.Curriculum_drop_icon__2BeHz{
  position: absolute !important;
  top: 7px;
  right: 10px;
  
}
.Curriculum_banner__RLSvq img{
  background: cover !important;
  width: 100%;
}


.Curriculum_resTable__8QdnI{
  /* overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;   */
}

.Curriculum_resTable__8QdnI::-webkit-scrollbar {
  display: none;
}


@media screen and (min-width: 1200px) {
  .Curriculum_txt__3azb5 {
    width: 100%;
  }
}

@media screen and (min-width: 1100) {
  .Curriculum_txt__3azb5 {
    width: 900px;
  }
}
@media screen and (max-width: 750) {
  .Curriculum_txt__3azb5 {
    width: 200px;
  }
}

.HelpFromTutor_tutorDiv__1Za55 p {
  display: inline-block;
  font-size: 14px;
  color: #686f7a;
  margin-bottom: 0;
  line-height: 27px;
}

.HelpFromTutor_dashimg__1LdB8 {
  height: 60vh;
  overflow: hidden;
}

.HelpFromTutor_hr__2OUd7 {
  margin-top: 15px;
  margin-bottom: 10px;
  clear: both;
}

.HelpFromTutor_curriculum__1f4Dl,
.HelpFromTutor_subject__BquHt,
.HelpFromTutor_topic__2E4sN,
.HelpFromTutor_chapter__AAfNj {
  float: left;
  margin-right: 10px;
  margin-top: 8px;
  font-size: 16px;
  line-height: 20px;
}

.HelpFromTutor_tutorFeatures__38-yT {
  /* background: #e9edf6; */
  background: #e9eaec;
  margin-top: 10px;
  padding: 10px;
  overflow: hidden;
}

.HelpFromTutor_tutorFeatures__38-yT span {
  cursor: pointer;
  padding: 4px 10px;
  margin-left: 10px;
  border-radius: 2px;
  float: right;
  color: #fff;
  font-size: 13px;
  letter-spacing: 0.5px;
}

.HelpFromTutor_tutorFeatures__38-yT .HelpFromTutor_replay__JlBne {
  background: #7986cb;
}

.HelpFromTutor_tutorFeatures__38-yT .HelpFromTutor_resolve__17At8 {
  background: #66bb6a;
}

.HelpFromTutor_tutorFeatures__38-yT .HelpFromTutor_feedback__15EnC {
  background: #42a5f5;
}

.HelpFromTutor_raiseQueryDiv__2B_OA select {
  height: 42px;
}

.HelpFromTutor_status__2o_qa {
  font-weight: 500;
}

.HelpFromTutor_scheduledDate__IyDkV {
  margin-left: 20px;
  font-weight: 500;
}

.HelpFromTutor_open__2lM6i {
  display: block;
}

.HelpFromTutor_close__AdGVL {
  display: none;
}
.HelpFromTutor_requestQuery__20RWh{
  background-color: #4A1E75 !important;
  color: #fff !important;
}
.HelpFromTutor_backrequests__h9Qft{
   background-color: #4A1E75 !important;
  color: #fff !important;
}
.HelpFromTutor_submitRequest__31uda{
    background-color: #4A1E75 !important;
  color: #fff !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
  height: 38px;
}
#combo-box-demo {
  position: absolute;
  width: 100%;
}

.Lab_LabDiv__2LOTI h5,
.Lab_queriesDiv__1oGfS h5 {
  font-size: 15px;
  font-weight: 500;
  color: #000000;
}

.Lab_LabDiv__2LOTI p {
  display: inline-block;
  font-size: 14px;
  color: #686f7a;
  margin-bottom: 0;
  margin-top: 3px;
}

.Lab_LabSummary__139q4 {
  background: #fff;
  width: 100%;
  float: left;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #efefef;
  box-shadow: none;
  transition: all 0.2s ease-in-out;
}

.Lab_LabSummary__139q4 p {
  display: block;
  padding: 12px 10px;
  text-align: left;
  border-bottom: 1px dashed #efefef;
  margin: 0px;
}

.Lab_LabSummary__139q4 p:last-child {
  padding-bottom: 5px;
  border-bottom: 0;
}

.Lab_LabSummary__139q4 .Lab_badge__1sBHJ {
  float: right;
  padding: 6px 8px;
  font-weight: 400;
  font-size: 13px;
  width: 30px;
}

.Lab_testType__9-Qee {
  width: 110px;
  float: left;
  background: #fff;
  color: #0077cd;
  padding: 7px 10px;
  margin-right: 10px;
  border-radius: 3px;
  font-weight: 500;
  cursor: pointer;
}

.Lab_active__Fm8J_ {
  background: #0077cd;
  color: #fff;
}

.Lab_LabResult__Jpe5C {
  display: block;
  background: transparent;
  margin-top: 8px;
  padding: 3px 10px;
  color: #fff;
  border-radius: 3px;
  text-align: center;
}

.Lab_labPass__2fFcP {
  background: #4caf50;
}

.Lab_labPassAvg__tafx0 {
  background: #ffbf00;
}

.Lab_labFail__JkOmS {
  background: #f71b1b;
}

.Sessions_sessionsDiv__3bJIr h2 {
  font-size: 15px;
  margin-bottom: 5px;
  margin-top: 0;
  color: #333;
}
.Sessions_dashimg__1BR7w {
  /* height: 60vh;
  overflow: hidden; */
    background-image: url(../../static/media/group3.e7c1db50.png);
     overflow: hidden;
 padding-top: 320px;
  padding-bottom: 230px;
    background-repeat: no-repeat;
  background-position: center;
    background-size: cover;
}
.Sessions_styleTab__1Brth{
  border-radius: 40px;
  border: 2px solid red;
}
.Sessions_sessionsDiv__3bJIr p {
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 0;
}

.Sessions_sessionsDiv__3bJIr span {
  font-size: 12px;

  padding: 5px 10px;
  border-radius: 10px;
  color: #fff;
  letter-spacing: 1px;
}

.Sessions_sessionsDiv__3bJIr button {
  margin-top: 12px;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.16), 0 0px 0px 0 rgba(0, 0, 0, 0.12);
  background-color: #ea9f07;
  border-color: #ea9f07;
  width: 100%;
  padding: 5px;
  border-radius: 3px;
}

.Sessions_sessionPreviewDiv__1Lfx3 {
  background: #fff;
  margin-bottom: 20px;
  float: left;
  width: 100%;
  border-radius: 6px;
  border: 2px solid #eee;
  padding: 15px;
  box-shadow: 0 0 11px -6px rgb(187 187 187 / 65%);
  font-size: 14px;
}

.Sessions_sessionPreviewDiv__1Lfx3 table th {
  border: 0px;
}

.Sessions_sessionPreviewDiv__1Lfx3 table td {
  border: 0px;
}

.Sessions_sessionPreviewDiv__1Lfx3 h2 {
  font-size: 22px;
  margin-bottom: 17px;
  font-weight: 600;
  color: #333;
  text-align: center;
}

.Sessions_testBack__k3xrf {
  position: absolute;
  right: 10px;
}
.Sessions_testBack1__1_J7x{
  position: absolute;
  right: 10px;

}

.Sessions_testStart__2puXf {
  position: absolute;
  right: 10px;
  bottom: 20px;
}

.sessionForm .label-heading {
  width: 100px;
  float: left;
  position: relative;
  top: 8px;
}

.sessionForm .form-control,
.sessionForm .react-datepicker-wrapper {
  font-size: 15px !important;
  width: 70% !important;
}

.sessionForm .react-datepicker-wrapper input {
  width: 100% !important;
}

.sessionForm .error {
  margin-left: 100px;
}

.calender_dashimg__htwjk {
  height: 60vh;
  overflow: hidden;
}
.calender_timeWidth__3rArY {
  width: 150px !important;
}
.calender_react-datepicker__time-container__2K98- {
  width: 150px !important;
}
.calender_relative__zrqJ_{
  position: relative !important;
  *border: 1px solid red;
  width: auto !important;
}

.calender_drop_icon__112JP{
  position: absolute !important;
  top: 7px;
  right: 130px;
  
}

@media screen and (max-width: 991px) {
  .calender_drop_icon__112JP{
    position: absolute !important;
    top: 7px;
    right: 40px;
    
  }
}

@media screen and (max-width: 575px) {
  .calender_drop_icon__112JP{
    position: absolute !important;
    top: 7px;
    right: 60px;
    
  }
}

@media screen and (max-width: 525px) {
  .calender_drop_icon__112JP{
    position: absolute !important;
    top: 7px;
    right: 40px;
    
  }
}

@media screen and (max-width: 412px) {
  .calender_drop_icon__112JP{
    position: absolute !important;
    top: 7px;
    right: 20px;
    
  }
}

#heading {
  color: rgb(79, 157, 202);
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.head {
  font-size: 65px;
}

.topBg{
  background: url(../../static/media/CurriculamPage.e88f8674.png);
  height: 551px;
}

#head-content {
  font-size: x-large;
  max-width: 550px;
  margin: auto;
  color: rgb(83, 83, 83);
  padding: 30px 0 !important;
}

.main {
  display: grid;
  grid-template-columns: auto auto auto auto;
  -ms-grid-column-align: center;
  *margin-top: 3%;
}

.outer {
  width: 20vw;
}

#blocks {
  display: flex;
  margin: auto;
  margin-top: 22%;
}

.block-heading {
  font-size: 20px !important;
  line-height: 24px !important;
  font-weight: 600 !important;
}

.info {
  line-height: 19px !important;
  font-size: 16px !important;
}

.amount {
  font-size: 32px !important;
  line-height: 39px !important;
  font-weight: 700 !important;
  color: #20639B !important;
  margin-top: 25px;
}

.btn2{
  margin-top: 25px;
  padding: 5px 13px;
  background: #F1E3FE;
  border: 1px solid #4A1E75;
  border-radius: 30px;
  margin-bottom: 20px;
  font-size: 20px;
  color: #464646 !important;
  font-weight: 500;
}

.btn1{
  margin-top: 25px;
  padding: 5px 13px;
  background: #4A1E75;
  border: 1px solid #4A1E75;
  border-radius: 30px;
  margin-bottom: 20px;
  font-size: 20px;
  color: white !important;
  font-weight: 500;
}

.block {
  width: 35vw;
  *height: 30vh;
  padding: 25px 10px;
  background-color: #C5F9D5;
  border-radius: 8px;
}



#button {
  width: 100%;
  color: red;
  /* margin: auto; */
}

@media only screen and (max-width: 1064px) {
  .main {
    display: grid;
    grid-template-columns: auto auto auto ;
  
  }
  .outer {
    width: 25vw;
   
  }
}

@media only screen and (max-width: 912px) {
  .outer {
    width: 30vw;
   
  }

  .block-heading {
    font-size: 20px !important;
    line-height: 24px !important;
    font-weight: 600 !important;
  }

  .info {
    line-height: 19px !important;
    font-size: 16px !important;
  }

  .amount {
    font-size: 32px !important;
    line-height: 39px !important;
    font-weight: 700 !important;
  }
}

@media only screen and (max-width: 836px) {
  .main {
    display: grid;
    grid-template-columns: auto auto ;
    *border: 1px solid red;
    margin: 0 auto;
   
  }
  .outer {
    width: 40vw;
    *border: 1px solid red;
  }
  .block {
    width: 40vw;
  }
}

@media only screen and (max-width: 605px) {
  .main {
   display: flex;
   flex-direction: column; 
  }
  .outer {
    width: 75vw;
    *border: 1px solid red;
  }
  .block {
    width: 75vw;
  }
}

.box_img {
  /* margin: 0 auto !important; */
  height: 60px;
  width: 60px;
}


.Topbar_navbar__3I0eK {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 75px;
  background: #fff;
  display: flex !important;
  align-items: center;
  box-shadow: 0 0.052083333in 11.25pt -9px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #e6e6e6;
  /* margin-bottom: 10px; */
}

.Topbar_dropdownMenu__31pdm {
  right: 0px !important;
  left: auto !important;
  width: 500px;
 min-height: 250px !important;
}
.Topbar_dropdownMenu1__iqPXE {
  right: 0px !important;
  left: auto !important;
  /* width: 500px; */
}
.Topbar_logo__33g2n{
  /* border: 2px solid blue; */
}
/* .active{
  background-color: #e6e6e6 !important;
} */
.Topbar_navbarNav1__1TR_-{
  display: flex;
  justify-content: space-between;
  width: 70%;
}
.Topbar_navbarNav1__1TR_- i {
  font-size: 35px;
  background-color: none;
}
.Topbar_navbarNav1__1TR_- li{
  font-size: 20px;
}
.Topbar_navbarNav1__1TR_- li:hover{
  background-color: lightgray;
  /* padding: 2px; */
}
.Topbar_map__1Mt6v {
  height: 10px;
}
.Topbar_flat__3BJ7h{
  background-color:red
}
.Topbar_studentNotification__O7lWA{
   padding-left: 2%;
}
.Topbar_iconText__lIpiP{
  display:flex;
  align-items: center;
}
.cardDiv {
  background: f5f5f5;
  margin-bottom: 10px;
  float: left;
  width: 100%;
  border-radius: 6px;
  border: 4px solid #eee;
  padding: 15px;
}
.cardDivtop {
  /* background-color: rgb(255, 136, 0); */
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  /* align-content: space-around; */
  /* margin-top: 80px;
  margin-left: 40px; */
}
.home {
  /* background-color: aqua; */
  display: flex;
  margin-right: 65px;
}

.cardDivAss {
  /* background: #fff; */
  /* margin-bottom: 20px; */
  /* float: left; */
  /* width: 209px; */
  /* border-radius: 29px; */
  /* border: 2px solid #eee; */
  /* padding: 15px; */
  /* height: 275px; */
  /* background-color: #ffa04d; */
}

.cardDivSess {
  /* background: #fff;
  margin-bottom: 20px;
  float: left;
  width: 209px;
  border-radius: 29px;
  border: 2px solid #eee;
  padding: 15px;
  height: 275px;
  background: #0747a6; */
}

.cardDivMy {
  /* background: #fff;
  margin-bottom: 20px;
  float: left;
  width: 188px;
  height: 153px;
  border-radius: 29px;
  border: 2px solid #eee;
  padding: 15px;
  background: #fdda5f; */
}
/* h4::before {
  content: "---";
  background-color: #c182f9;
  color: #c182f9;
  font-weight: bold;
  border-radius: 100%;
} */

.cardDiv1 {
  /* background-color: #ECF3FF;; */
  width: 1250px;
  height: 244px;
  top: 437px;
  left: 67px;
  radius: 6px;
  margin: 15px;
  border-radius: 6px;
}
@media only screen and (max-width: 1300px) {
  .cardDiv1 {
    width: 1180px;
    /* height: 244px;
  top: 437px;
  left: 67px;
  radius: 6px;
  margin: 15px;
  border-radius: 6px; */
  }
}

.header_navbar__ZEyq4 {
  position: fixed;
  /* z-index: 999; */
  width: 100%;
  height: 60px;
  background: #fff;
  display: flex !important;
  align-items: center;
  box-shadow: 0 0.052083333in 11.25pt -9px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #e6e6e6;
}

.header_dropdownMenu__1XsdZ {
  right: 0px !important;
  left: auto !important;
  width: 500px;
  min-height: 250px !important;
}
.header_dropdownMenu1__2A3jJ {
  right: 0px !important;
  left: auto !important;
  /* width: 500px; */
}

.header_navbarNav__zw62P i {
  font-size: 25px;
}
.header_rejectAdmin__1Mmyw{
  padding-left: 2%;
}

.nav-link.active {
  color: #222 !important;
  background-color: #fff !important;
}
.nav-link{
  /* color: #fff !important; */
}
/* .nav-link.active1 {
  background-color: blue;
} */
.sidebar_navbarHeader__eeBK7 {
  padding: 3px 5px;
  background: #4A1D75;
}

.sidebar_navbarBrand__3PiT_ {
  font-size: 22px !important;
  font-weight: 600 !important;
  letter-spacing: 1px !important;
}

.sidebar_mainSidebar__3Y5Zd {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 240px;
  z-index: 9999;
  transition: width 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  background: #4A1D75;
  /* box-shadow: rgba(146, 153, 184, 0.063) 0px 0px 30px; */
  overflow-y: auto;
}

.sidebar_mainSidebar__3Y5Zd ul {
  padding-left: 15px;
  position: absolute;
  width: 100%;
  height: 100%;
  padding-left: 0px;
}

.sidebar_mainSidebar__3Y5Zd li {
  padding: 2px 0px;
  cursor: pointer;
}

.sidebar_mainSidebar__3Y5Zd a {
  color: #fff;
  font-size: 14px;
  text-decoration: none;
  font-weight: 400;
  padding: 10px 15px !important;
}

.sidebar_sidebaricons__1UYxJ {
  color: #dadada;
  margin-right: 10px;
  font-size: 20px;
  margin-right: 6px;
  position: relative;
  top: 3px;
  width: 30px;
}
.sidebar_nav-link__5qHRp.sidebar_active__3z1GY {
  color: #333 !important;
  background-color: #fff !important;
}

.logo {
  height: 200px;
  width: 200px;
  border: 3px dotted #000;
  padding: 5px;
}
.imageLogo {
  height: 200px;
  width: 200px;
}
.input-group-text {
  width: 30% !important;
  border-radius: 40px;
}
.CurriculamHeader {
  display: flex;
  justify-content: space-between;
  width: 98% !important;
  margin-bottom: 15px !important;
  align-items: center;
  /* padding: 15px; */
}
@media only screen and (max-width: 1280px) {
  .CurriculamHeader {
    width: 84% !important;
  }
}
.btn btn-sm add-btn {
  background-color: #4a1d75 !important;
}
body {
  margin: 30px;
}
.search {
  position: relative;
  color: #aaa;
  font-size: 14px;
}

.search {
  display: inline-block;
}

.search input {
  width: 230px;
  height: 35px;

  background: #fcfcfc;
  border: 1px solid #aaa;
  border-radius: 5px;
  box-shadow: 0 0 3px #ccc, 0 10px 15px #ebebeb inset;
}

.search input {
  text-indent: 32px;
}
.search .fa-search {
  position: absolute;
  top: 10px;
  left: 10px;
}

/* .search .fa-search {left: auto; right: 10px;} */

.table_container table thead tr th {
  background-color: #f1e3fe !important;
  padding-bottom: 16px !important;
}
.maindiv {
  height: 80vh !important;
  overflow: scroll !important;
  border: 1px solid red;
  visibility: visible;
}
.uploadButton{
    background: #4A1D75;
    color: white;
    padding: 5px;
    border-radius: 5px;
}


.courseHeader{
    display: flex;
  justify-content: space-between;
  width: 98% !important;
  margin-bottom: 15px !important;
  align-items: center;
}
.input-group-text{
    width: 30% !important;
  border-radius: 40px !important;
}
@media only screen and (max-width: 1280px){
  .courseHeader{
    width: 85% !important;
  }
}
/* body { margin: 30px; } */
.search {
  position: relative;
  color: #aaa;
  font-size: 5px;
}

.search {display: inline-block;}

.search input {
  width: 230px;
  height: 35px;

  background: #fcfcfc;
  border: 1px solid #aaa;
  border-radius: 5px;
  box-shadow: 0 0 3px #ccc, 0 10px 15px #ebebeb inset;
}

.search input { text-indent: 32px;}
.search .fa-search { 
  position: absolute;
  top: 10px;
  left: 10px;
}


.table_container table thead tr th{
  color: #20639B !important;
  background-color: #F1E3FE !important;
  padding-bottom:16px !important;
}
.table_container1 table thead tr th{
background-color: #F1E3FE !important;
padding-bottom:16px !important;
}
.subjectHeader{
     display: flex;
  justify-content: space-between;
  width: 98% !important;
   margin-bottom: 15px !important;
  align-items: center;
}
.input-group-text{
  width: 40% !important;
  border-radius: 40px;
}
body { margin: 30px; }
.search {
  position: relative;
  color: #aaa;
  font-size: 14px;
}

.search {display: inline-block;}

.search input {
  width: 230px;
  height: 35px;

  background: #fcfcfc;
  border: 1px solid #aaa;
  border-radius: 5px;
  box-shadow: 0 0 3px #ccc, 0 10px 15px #ebebeb inset;
}

.search input { text-indent: 32px;}
.search .fa-search { 
  position: absolute;
  top: 10px;
  left: 10px;
}
.table_container table thead tr th{
    background-color: #F1E3FE !important;
  padding-bottom:16px !important;
}

.relative{
  position: relative !important;
  *border: 1px solid red;
  width: auto !important;
}

.drop_icon{
  position: absolute !important;
  top: 30px;
  right: 10px;
  
}
#combo-box-demo {
  position: absolute;
  width: 81%;
  overflow: hidden;
}
.input-group-text{
   width: 30% !important;
  border-radius: 40px;
}
.questionHeader{
  display: flex;
  justify-content: space-between;
  width: 98% !important;
  margin-bottom: 15px !important;
  align-items: center;
}
@media only screen and (max-width: 1280px){
  .questionHeader{
     width: 85% !important;
  }
}
.table_container table thead tr th{
  color: #20639B !important;
  background-color: #F1E3FE !important;
  padding-bottom:16px !important;
}

.relative{
  position: relative !important;
  *border: 1px solid red;
  width: auto !important;
}

.drop_icon{
  position: absolute !important;
  top: 32px;
  right: 10px;
  
}
.templateHeader {
  display: flex;
  justify-content: space-between;
  width: 98% !important;
  margin-bottom: 15px !important;
  align-items: center;
}
.input-group-text {
  width: 30% !important;
  border-radius: 40px;
}
@media only screen and (max-width: 1280px) {
  .templateHeader {
  }
}
.table_container table thead tr th {
  background-color: #f1e3fe !important;
  padding-bottom: 16px !important;
}
.autocompl {
  padding: 30px !important;
}

.relative{
  position: relative !important;
  *border: 1px solid red;
  width: auto !important;
}

.drop_icon{
  position: absolute !important;
  top: 32px;
  right: 10px;
  
}
.assessmentHeader{
   display: flex;
  justify-content: space-between;
  width: 98% !important;
  margin-bottom: 15px !important;
  align-items: center;
}
#time-input::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
}
/* .without_ampm::-webkit-datetime-edit-ampm-field {
  display: none;
} */
.in_put{
  background-color: white;
  display: inline-flex;
  border: 1px solid #ccc;
  color: #555;
  width :90px;
   
  }
@media only screen and (max-width: 1280px){
   .assessmentHeader{
  width: 85% !important;
}
}
body { margin: 30px; }
.search {
  position: relative;
  color: #aaa;
  font-size: 14px;
}

.search {display: inline-block;}

.search input {
  width: 230px;
  height: 35px;

  background: #fcfcfc;
  border: 1px solid #aaa;
  border-radius: 5px;
  box-shadow: 0 0 3px #ccc, 0 10px 15px #ebebeb inset;
}

.search input { text-indent: 32px;}
.search .fa-search { 
  position: absolute;
  top: 10px;
  left: 10px;
}
.staffHewader{
  display: flex;
  justify-content: space-between;
  width: 98% !important;
  margin-bottom: 15px !important;
  align-items: center;
  margin-top: 10px !important;
}
@media only screen and (max-width: 1280px){
    .staffHewader{
  width: 74% !important;
}
}
body { margin: 30px; }
.search {
  position: relative;
  color: #aaa;
  font-size: 14px;
}

.search {display: inline-block;}

.search input {
  width: 230px;
  height: 35px;

  background: #fcfcfc;
  border: 1px solid #aaa;
  border-radius: 5px;
  box-shadow: 0 0 3px #ccc, 0 10px 15px #ebebeb inset;
}

.search input { text-indent: 32px;}
.search .fa-search { 
  position: absolute;
  top: 10px;
  left: 10px;
}

.relative{
  position: relative !important;
  *border: 1px solid red;
  width: auto !important;
}

.drop_icon{
  position: absolute !important;
  top: 32px;
  right: 10px;
  
}
body { margin: 30px; }
.search {
  position: relative;
  color: #aaa;
  font-size: 14px;
}

.search {display: inline-block;}

.search input {
  width: 230px;
  height: 35px;

  background: #fcfcfc;
  border: 1px solid #aaa;
  border-radius: 5px;
  box-shadow: 0 0 3px #ccc, 0 10px 15px #ebebeb inset;
}

.search input { text-indent: 32px;}
.search .fa-search { 
  position: absolute;
  top: 10px;
  left: 10px;
}
.videoHeader{
  display: flex;
  justify-content: space-between;
  width: 98% !important;
  margin-bottom: 15px !important;
  align-items: center;
}

/* width */
.scroll-div::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
.scroll-div::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scroll-div::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.scroll-div::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.overflowList{
  overflow: auto !important;
  height: 70vh !important;
}
body { margin: 30px; }
.search {
  position: relative;
  color: #aaa;
  font-size: 14px;
  margin-bottom: 10px;
}

.search {display: inline-block;}

.search input {
  width: 230px;
  height: 35px;

  background: #fcfcfc;
  border: 1px solid #aaa;
  border-radius: 5px;
  box-shadow: 0 0 3px #ccc, 0 10px 15px #ebebeb inset;
}

.search input { text-indent: 32px;}
.search .fa-search { 
  position: absolute;
  top: 10px;
  left: 10px;
}
.teacherSession_date__29e7w {
  background-color: #4A1E75;
  border-radius: 5px;
  padding: 3px 20px;
  color: rgb(255, 255, 255);
}

.teacherSession_head__hAvgu {
  font-size: 18px;
}
.teacherSession_amount__gYm9l{
  font-size: 12px !important;
  font-weight: 700;
}
.teacherSession_num__1T-1d {
  font-size: 23px;
  text-align: center;
}
.teacherSession_click__3VJyx{
  margin: 3px;
}
.teacherSession_join__3SR2I{
  background: #EBD8FE !important;
  color:  #4A1E75 !important;
  padding: 5px 30px !important;
}
.teacherSession_join__3SR2I:hover{
  background-color: #e0c4fc !important;
}
.teacherSession_cardheading__26eeh{
  padding: 17px 17px;
  background: #F1E3FE;
  border-radius: 10px;
  color:#20639B;
}
.adminSession_date__3JesM {
  background-color: #4a1d75;
  border-radius: 50px;
  padding: 3px 20px;
  color: rgb(255, 255, 255);
}

.adminSession_head__1y8G_ {
  font-size: 18px;
}
.adminSession_num__7PJ4J {
  font-size: 23px;
  text-align: center;
}
.adminSession_click2__Wn-6P {
  height: 30px;
  margin: 3px;
  /* padding: 50px; */
  background-color: #e0f6f6 !important;
  color: #1db9aa !important;
  border: 2px solid #e0f6f6;
}
.adminSession_click1__2U8CZ {
  height: 30px;
  margin: 3px;
  /* padding: 50px; */
  background-color: #cbb4b4 !important;
  color: red !important;
  border: 2px solid #cbb4b4;
}
.adminSession_acceptedTecher__2enUT {
  max-height: 150px;
  overflow: scroll;
}

.adminSession_teacherLi__1kKhl {
  display: flex;
  flex-direction: row;
  grid-gap: 30px;
  gap: 30px;
  background-color: red;
}
.adminSession_adminSession__2k0e- {
  color: #20639b;
}

.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4D4D4D;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19AB27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128D15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4D4D4D;
  border-radius: 50%;
  background-color: #FAFAFA;
  box-sizing: border-box;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19AB27;
}

.react-toggle--focus .react-toggle-thumb {
  box-shadow: 0px 0px 2px 3px #0099E0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  box-shadow: 0px 0px 5px 5px #0099E0;
}

.record_progress__3LvGJ {
  height: 10px;
  border: 1px solid #f1f1f1;
  background: #e0e0e0;
  border-radius: 10px;
}

.record_progressBar__H1fJs {
  height: 8px;
  border-radius: 10px;
}

.record_chaptersDiv__2YrFk table {
  background: #fff;
}

.record_chaptersDiv__2YrFk table td:nth-child(2) {
  font-weight: 500;
  width: 200px;
}

.record_chaptersDiv__2YrFk table td:nth-child(3) {
  width: 40%;
}

.record_modalBody__LIoVG {
  min-height: 400px;
}

.record_notAvailable__1k3MM {
  text-align: center;
  width: 100%;
  padding-top: 150px;
  font-size: 18px;
  font-weight: 500;
}

/* body { margin: 30px; } */
.search {
  position: relative;
  color: #aaa;
  font-size: 14px;
  margin-bottom: 10px !important;
}

.search {display: inline-block;}

.search input {
  width: 230px;
  height: 35px;

  background: #fcfcfc;
  border: 1px solid #aaa;
  border-radius: 5px;
  box-shadow: 0 0 3px #ccc, 0 10px 15px #ebebeb inset;
}

.search input { text-indent: 32px;}
.search .fa-search { 
  position: absolute;
  top: 10px;
  left: 10px;
}


.table_container2 table thead tr th{
    background-color: #F1E3FE !important;
  padding-bottom:18px !important;
}
.Admin_admindashome__dlNHV {
  margin-left: 60px;
}
.Admin_main__2540C {
  display: flex;
  justify-content: space-around;
}
.Admin_card__n8Pdw {
  margin: 25px;
  padding: 20px;
  width: 272px;
  height: 142px;

  border-radius: 8px;
  background: #a9dddd;
}
.Admin_curri__2I5zB {
  display: flex;
  justify-content: space-between;
}
.Admin_card2__3sf4f {
  margin: 25px;
  padding: 20px;

  width: 272px;
  height: 142px;
  border-radius: 8px;
  background: #fbb0b8;
}
.Admin_assess__2APlJ {
  display: flex;
  justify-content: space-between;
}
.Admin_card3__8N_Bf {
  margin: 25px;
  width: 272px;
  height: 142px;
  padding: 20px;
  border-radius: 8px;
  background: #ccbfeb;
}
.Admin_sessio__3wwcZ {
  display: flex;
  justify-content: space-between;
}
.Admin_submain__2fYtg {
  display: flex;
  justify-content: space-around;
  /* padding: 0 25%; */
  padding-top: 5%;
  padding-left: 19%;
  padding-right: 15%;
}
.Admin_card4__1iO_P {
  margin: 25px;
  width: 272px;
  height: 142px;
  padding: 20px;
  border-radius: 8px;
  background: #ffd59c;
}
.Admin_student__3oR2L {
  display: flex;
  justify-content: space-between;
}
.Admin_card5__1ndTh {
  margin: 25px;
  width: 272px;
  height: 142px;
  padding: 20px;
  border-radius: 8px;
  background: #a9dbff;
}
.Admin_teacher__3Tn9u {
  display: flex;
  justify-content: space-between;
}

.form-heading {
  font-size: 23px;
  text-align: left;
 /*  margin-bottom: 15px;
  margin-top: 15px; */
  font-weight: 600;
  width: 100%;
  padding: 15px 22px;
}
.session_heading h1{
  font-size: 23px;
  text-align: left;
  color: #fff;
 /*  margin-bottom: 15px;
  margin-top: 15px; */
  font-weight: 600;
  width: 100%;
  padding: 15px 22px;
  background:  #4A1E75 !important;
  border-radius: 5px !important;
}

.assessment_heading .form-heading{
  width: 100% !important;
  padding: 10px 20px;
  background: #4A1E75;
  color: #ffffff;
  font-size: 20px;
  border-radius: 5px;
}

.calender_heading .form-heading{
  background: #4A1E75 !important;
  color: #ffffff !important;
  padding: 10px 20px;
  border-radius: 5px;
}

.ck-content {
  min-height: 150px;
  height: 150px !important;
  overflow-y: auto !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.add-btn-div {
  width: 100%;
  border-radius: 40px;
  /* padding: 15px 0; */
  /* margin-top: -60px; */
  /* text-align: end; */
  
}

.add-btn {
  float: right;
}
.add-btn-1{
  float: left; 
}

.text-view {
  width: 100%;
  border: 1px solid #efecec;
  min-height: 50px;
  vertical-align: middle;
  border-bottom: 0px;
  padding: 12px;
  display: flex;
}

.text-view:last-child {
  border-bottom: 1px solid #efecec;
}

.text-view label {
  font-weight: 500;
  color: #908e8e;
  display: block;
  margin: 2px;
  font-size: 14px;
  float: left;
  width: 35%;
}

.text-view span {
  font-weight: 400;
  font-size: 15px;
  width: 65%;
}

.text-view-long span {
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
}

.closePage {
  clear: both;
  width: 100%;
  background: #ffffff;
  padding: 10px 15px;
  margin-bottom: 20px;
  border-bottom: 1px solid #dcdcdc;
  /* margin-top: -18px; */
}

.closePage h3 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 5px;
  float: left;
}

.closePage .btn {
  float: right;
  margin-top: 2px;
  width: 100px;
  letter-spacing: 1px;
  font-weight: 500;
}

.table {
  margin-bottom: 0px;
}

.table > thead > tr {
  background-color: #ececec !important;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 0px;
}

.table td {
  vertical-align: middle !important;
}

.table td:last-child {
  width: 150px;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 0px solid #dee2e6;
  color: #20639B;
  background: white;  
  font-weight: 600;
  font-size: 15px;
}

.question-list {
  padding: 20px;
  line-height: 30px;
}

.la-check-circle {
  color: green;
  font-size: 20px;
  position: relative;
  top: 2px;
}

i.las.la-times-circle {
  color: red;
  font-size: 20px;
  position: relative;
  top: 2px;
}
.question-list .ans {
  margin-bottom: 5px;
}

.form-control {
  font-size: 20px !important;
}

.staffSelection {
  position: relative;
  top: 40px;
  left: 15px;
}

.eventModal .text-view label {
  width: 170px;
  color: #000;
}

.eventModal .text-view {
  width: 100%;
  border: 0px solid #efecec;
  min-height: 30px;
  vertical-align: middle;
  border-bottom: 0px;
  padding: 7px;
}

.eventModal .text-view span {
  font-weight: 400;
  font-size: 14px;
}

.eventModal .text-view ol {
  margin-left: 150px;
  margin-bottom: 0px;
}

.eventModal .modal-title {
  font-size: 18px;
}

/* 
* {
  box-sizing: border-box;
}
.topCardLeft h5 {
  font-size: 14px;
  margin-bottom: 12px;
  text-align: left;
  color: #9c9c9c;
  font-weight: 400;
}

.topCardLeft h2 {
  font-size: 17px;
  margin-bottom: 10px;
  margin-top: 0;
}

.topCardLeft span {
  font-size: 12px;
  background: #0079d2;
  padding: 5px 10px;
  border-radius: 10px;
  color: #fff;
  letter-spacing: 1px;
}


.topCardRight {
  margin-left: 80px;
  display: flex;
  justify-content: space-around;
  align-content: space-around;
}

.topCardRight img {
  width: 58px;
}


.queriesDiv h5 {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-bottom: 5px;
}
.assignmentDiv1 h5 {
  margin-top: 45px !important;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-bottom: 5px;
}

.assignmentDiv p {
  display: inline-block;
  font-size: 14px;
  color: #686f7a;
  margin-bottom: 0;
  margin-top: 3px;
}

.queriesDiv p {
  display: inline-block;
  font-size: 14px;
  color: #686f7a;
  margin-bottom: 0;
  line-height: 27px;
}

.hr {
  margin-top: 10px;
  margin-bottom: 10px;
}

.curriculum {
  margin-right: 10px;
  font-size: 15px;
}

.subject,
.chapter {
  float: left;
  margin-right: 10px;
  font-size: 15px;
}

.queryDiv {
  line-height: 28px;
}

.queryDiv .subject {
  float: none;
  margin-right: 10px;
  font-size: 14px;
  width: 100%;
}

.queryDiv .topic {
  float: none;
  margin-right: 10px;
  font-size: 14px;
}

.queryDiv .chapter {
  margin-right: 10px;
  font-size: 14px;
}

.sessionsDiv h2 {
  font-size: 15px;
  margin-bottom: 5px;
  margin-top: 0;
  color: #333;
}

.sessionsDiv p {
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 0;
}

.sessionsDiv span {
  font-size: 12px;
  background: #0079d2;
  padding: 5px 10px;
  border-radius: 10px;
  color: #fff;
  letter-spacing: 1px;
}

.sessionsDiv button {
  margin-top: 12px;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.16), 0 0px 0px 0 rgba(0, 0, 0, 0.12);
  background-color: #ea9f07;
  border-color: #ea9f07;
  width: 100%;
  padding: 5px;
  border-radius: 3px;
}
.card {
  margin: 25px;
  padding: 20px;
  width: 272px;
  height: 142px;
  top: 188px;
  left: 297px;
  border-radius: 8px;
  background: #fbb0b8;
}
.card2 {
  margin: 25px;
  padding: 20px;
  width: 272px;
  height: 142px;
  border-radius: 8px;
  background: #ffd59c;
}
.card3 {
  margin: 25px;
  width: 272px;
  height: 142px;
  padding: 20px;
  border-radius: 8px;
  background: #a9dbff;
}
.texts {
  color: rgba(0, 0, 0, 0.7);
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.circle {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  
}
.circle1 {
  width: 100%;
  height: 100px;
    width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  
}
.img_con {
  margin-top: 35px;
  height: 86px;
  background-color: white;
  padding: 2px;
  border-radius: 50%;
}

.filestudent {
  display: flex;
  gap: 2;
}
.totalcurri {
  display: flex;
  justify-content: space-between;
}
.totalstudent {
  display: flex;
  justify-content: space-between;
}
.totalsession {
  display: flex;
  justify-content: space-between;
}
.semicircle {
}
.totalassesment {
  display: flex;
  justify-content: center;
  width: 70%;
} */
.Dashboard_admindashome__xweET {
  /* margin-left: 60px; */
}
.Dashboard_main__1GY-Z {
  display: flex;
  justify-content: space-around;
}
.Dashboard_card__33n2g {
  margin: 25px;
  padding: 20px;
  width: 272px;
  height: 142px;

  border-radius: 8px;
  background: #a9dddd;
}
.Dashboard_curri__1TUTS {
  display: flex;
  justify-content: space-between;
}
.Dashboard_card2__2y_Le {
  margin: 25px;
  padding: 20px;

  width: 272px;
  height: 142px;
  border-radius: 8px;
  background: #fbb0b8;
}
.Dashboard_assess__3QwZ7 {
  display: flex;
  justify-content: space-between;
}
.Dashboard_card3__2Pve7 {
  margin: 25px;
  width: 272px;
  height: 142px;
  padding: 20px;
  border-radius: 8px;
  background: #ffd59c;
}
.Dashboard_sessio__1ems9 {
  display: flex;
  justify-content: space-between;
}
.Dashboard_submain__3cs8J {
  display: flex;
  justify-content: space-around;
  /* border: 2px solid red; */
   /* width: %; */
  /* padding: 0 7%; */
  padding-top: 3%;
  padding-left: 7%;
  padding-right: 7%;
}
.Dashboard_card4__3SOyn {
  margin: 25px;
  width: 450px;
  height: 142px;
  padding: 20px;
  border-radius: 8px;
  background: #CCBFEB;
}
.Dashboard_card5__2i6Bs {
  margin: 25px;
  width: 430px;
  height: 142px;
  padding: 20px;
  border-radius: 8px;
  background: #a9dbff;
}

.Dashboard_cardcompletedpend__35wQS{
  display: flex;
  justify-content: space-around;
  padding-top: 3%;
  /* border:2px solid red; */
  /* padding: 0 2%; */
  padding-left: 8%;
  padding-right: 7%;
}
.Dashboard_sessioncards__3gELe{
  display: flex;
  justify-content: space-around;
  width: 30%;
}
.Dashboard_queriescard__3fi9d{
   display: flex;
  justify-content: space-around;
  width: 30%;
  
}
.Dashboard_completesession__1_jiy{
   /* margin: 25px; */
   /* margin-left: 40px; */
   width: 200px;
   height: 140px;
    padding: 20px;
    background: #CCBFEB;
    border-radius: 8px;

}
.Dashboard_pendingsession__2BN-M{
  /* margin: 25px; */
   width: 200px;
   height: 140px;
    padding: 20px;
    background: #CCBFEB;
    border-radius: 8px;

}

.Dashboard_completequeries__1Rppp{
   /* margin: 25px; */
   width: 200px;
   height: 140px;
    padding: 20px;
    background: #A9DBFF;
    border-radius: 8px;
}
.Dashboard_pendingcard__omqaa{
   /* margin: 25px; */
   width: 200px;
   height: 140px;
    padding: 20px;
    background: #A9DBFF;
    border-radius: 8px;
}
.Dashboard_student__1X2G9 {
  display: flex;
  justify-content: space-between;
}

.Dashboard_teacher__2JZO4 {
  display: flex;
  justify-content: space-between;
}
totalSession{
  /* width: 50%; */
}
@media screen and (max-width: 1860px){
  .Dashboard_submain__3cs8J {
  display: flex;
  justify-content: space-around;
  /* border: 2px solid red; */
   /* width: %; */
  /* padding: 0 7%; */
  padding-top: 3%;
  /* padding-left: 7%;
  padding-right: 7%; */
}
  .Dashboard_cardcompletedpend__35wQS{
  display: flex;
  justify-content: space-around;
  padding-top: 3%;
  /* border:2px solid red; */
  /* padding: 0 2%; */
  /* padding-left: 8%;
  padding-right: 7%; */
}
.Dashboard_sessioncards__3gELe{
  display: flex;
  justify-content: space-around;
  width: 34%;
}
.Dashboard_queriescard__3fi9d{
   display: flex;
  justify-content: space-around;
  width: 34%;
  
}
}
@media screen and (max-width: 1650px){
  .Dashboard_sessioncards__3gELe{
  display: flex;
  justify-content: space-around;
  width: 38%;
}
.Dashboard_queriescard__3fi9d{
   display: flex;
  justify-content: space-around;
  width: 38%;
  
}

}
@media screen and (max-width: 1650px){
   .Dashboard_sessioncards__3gELe{
  display: flex;
  justify-content: space-around;
  width: 40%;
}
.Dashboard_queriescard__3fi9d{
   display: flex;
  justify-content: space-around;
  width: 40%;
  
}
}
@media screen and (max-width: 1450px){
     .Dashboard_sessioncards__3gELe{
  display: flex;
  justify-content: space-around;
  width: 42%;
}
.Dashboard_queriescard__3fi9d{
   display: flex;
  justify-content: space-around;
  width: 42%;
  
}
}
@media screen and (max-width:1400px){
      .Dashboard_sessioncards__3gELe{
  display: flex;
  justify-content: space-around;
  width: 45%;
}
.Dashboard_queriescard__3fi9d{
   display: flex;
  justify-content: space-around;
  width: 45%;
  
}
}
@media screen and (max-width:1350px){
      .Dashboard_sessioncards__3gELe{
  display: flex;
  justify-content: space-around;
  width: 48%;
}
.Dashboard_queriescard__3fi9d{
   display: flex;
  justify-content: space-around;
  width: 48%;
  
}
}
@media screen and (max-width:1300px){
  .Dashboard_submain__3cs8J {
  display: flex;
  justify-content: space-between;
  
  padding-top: 3%;
  /* padding-left: 7%;
  padding-right: 7%; */
}
  .Dashboard_cardcompletedpend__35wQS{
  display: flex;
  justify-content: space-between;
  padding-top: 3%;
 
}
      .Dashboard_sessioncards__3gELe{
  display: flex;
  justify-content: space-around;
  width: 48%;
}
.Dashboard_queriescard__3fi9d{
   display: flex;
  justify-content: space-around;
  width: 48%;
  
}
}

@media screen and (max-width:1250px) {
    .Dashboard_submain__3cs8J {
  display: flex;
  justify-content: space-between !important;
  padding-top: 3%;
  width: 100%;
   padding-left: 0 !important;
 padding-right: 0 !important;
  /* border: 2px solid red; */
  /* padding-left: 7%;
  padding-right: 7%; */
}
.Dashboard_card4__3SOyn {
  margin: 25px;
  width: 500px !important ;
  height: 142px;
  /* padding: 20px; */
  border-radius: 8px;
  background: #CCBFEB;
  /* border: 2px solid red; */
  
}
.Dashboard_card5__2i6Bs {
  margin: 25px;
  width: 480px !important;
  height: 142px;
  /* padding: 20px; */
  border-radius: 8px;
  background: #a9dbff;
}
.Dashboard_cardcompletedpend__35wQS{
  display: flex;
  justify-content: space-between!important;
  padding-top: 3%;
 padding-left: 0 !important;
 padding-right: 0 !important;
}
  .Dashboard_sessioncards__3gELe{
  display: flex;
  justify-content: space-around;
  width: 50%;
}
.Dashboard_queriescard__3fi9d{
   display: flex;
  justify-content: space-around;
  width: 50%;
  
}
}
@media screen and (max-width:1200px){
  .Dashboard_cardcompletedpend__35wQS{
  display: flex;
  justify-content: space-between!important;
  padding-top: 3%;
 padding-left: 0 !important;
 padding-right: 0 !important;
}
}





.TestImage_iconColor__2IvTf {
  color: blue !important;
  /* border: 2px solid yellow !important; */
}

.TestImage_text_cap__OB-DQ{
  text-transform: capitalize !important;
}

.TestImage_overflow_list__nHfzw{
  overflow: auto !important;
  height: 70vh !important;
}

.TestImage_main__1aKGh{
  *width: 100% !important;
  position: relative !important;
  
}
.TestImage_close_icon__g7gRV{
  color: red !important;
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 15px !important;
  cursor: pointer;
}

.TestImage_img_popup__N74rO{
  width: 40% !important;
  *height: 60vh !important;
  *background: green;
  position: absolute;
  left: 30% !important;
  top: 20vh !important;
}

.TestImage_pointer__H1tGs{
  cursor: pointer;
}
.TestImage_reject__210Tg{
  color: red;
  font-weight: bold;
}
.TestImage_accept__3Rs_m{
  color: blueviolet;
  font-weight: bold;
}
body{
    
    padding: 0 !important;
    margin: 0 !important;
}
.TeacherReport_MainDiv__1PVpp{
    background: #F1E3FE !important;
    height: 160vh !important;
    width: 100%;
    margin-bottom: 40px;
}
.TeacherReport_heading__1_JW8{
     width: 100% !important;
  padding: 10px 20px;
  background: #4A1E75;
}
.TeacherReport_assessmentHeading__2Xapm{
    margin-top: 3%;
    margin-left: 2%;
}
.TeacherReport_mainContainer__EW3SV{
    /* border:2px solid red */
}
.TeacherReport_firstRow__27v4K{
    display: flex;
    justify-content: center;
    margin-top: 60px;
}
.TeacherReport_secondRow__1ZEtf{
     display: flex;
    justify-content: center;
    height: 60vh;
    margin-top: 20px;
}
.TeacherReport_imgaICon__Csp82{
    /* font-size: 10px !important; */
    width: 50px;
}
/* .imgaICon4{
    font-size: 10px !important;
   
    height: 100px;

} */
.TeacherReport_vector1__3eZKm{
    background-color: #AB94E2;
   padding: 40px 60px;
    border-radius: 8px;
    margin-right: 20px;
    width:25vw
    /* display: flex; */
    /* justify-content: center;
    align-items: center;
    text-align: center; */
}
.TeacherReport_img1__uWE8W{
    background-color: #A9DDDD;
    border-radius: 50%;
    padding: 10px;
     width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    /* border: 2px solid red; */
     /* margin-left: 50px; */
     margin: 0 auto;

}
.TeacherReport_vector2__2GHD4{
    background-color: #AB94E2;
    /* padding: 40px; */
    border-radius: 8px;
    margin-left: 20px;
    padding: 40px 60px;
     width:25vw
}
.TeacherReport_img2__asSqs{
    justify-content: center;
    background-color: #FBB0B8;
    border-radius: 50%;
    padding: 10px;
      width: 100px;
    height: 100px;
      margin: 0 auto;
}
.TeacherReport_vector3__1YZja{
    background-color: #AB94E2;
    padding: 40px 63px;
    border-radius: 8px;
    height: 35vh;
    margin-right: 20px;
    width: 25vw;
}
.TeacherReport_img3__1b89D{
    background-color: #FFD59C;
    border-radius: 50%;
     width: 100px;
    height: 100px;
    padding: 10px;
      margin: 0 auto;
   
}
.TeacherReport_vector4__1nv0A{
    background-color: #AB94E2;
     padding: 40px 66px;
    border-radius: 8px;
    height: 35vh;
    margin-left: 20px;
     width: 25vw;
}
.TeacherReport_img4__3S-2z{
      background-color: #A9DBFF;
      border-radius: 50%;
    width: 100px;
    height: 100px;
    padding: 10px;
      margin: 0 auto;
      
}
.TeacherReport_LastBox__1qycu{
    display: flex;
    justify-content: center;
}
.TeacherReport_testDuration__3Wpyr{
    display: flex;
    justify-content: space-between;
    width: 55vw;
    background-color: #fff;
    border-radius: 8px;
    padding: 5px;
}
.Sidebar_mainSidebar__1cM1Y {
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  background: #fff;
  overflow: hidden;
  width: 240px;
  z-index: 1030;
  box-shadow: 0 0 11px -6px rgb(0 121 210 / 65%);
}

.Sidebar_sidebar_links_container__2yomd{
  background: #4A1D75 !important;
}

.Sidebar_nav-link__282dn.Sidebar_active__1qJOG {
  color: #333 !important;
  background-color: #fff !important;
}
.Sidebar_nav-link__282dn:hover {
  color: white !important;
}

.Sidebar_logo__3JEj2 {
  text-align: center;
}

.Sidebar_logo__3JEj2 img {
  height: 45px;
  margin: 5px auto;
  display: block;
}

.Sidebar_logo__3JEj2 span {
  font-weight: 500;
  font-size: 14px;
  margin-top: 12px;
  display: block;
}

.Sidebar_mainSidebar__1cM1Y ul {
  padding-left: 15px;
  position: absolute;
  width: 100%;
  height: 100%;
  padding-left: 0px;
  top: 45px;
}

.Sidebar_mainSidebar__1cM1Y li {
  cursor: pointer;
  padding: 2px 0px;
}

.Sidebar_mainSidebar__1cM1Y .Sidebar_navLink__3FUya {
  font-size: 14px;
  text-decoration: none;
  /* color: #333; */
  color: white;
  font-weight: 500;
  padding: 10px 15px !important;
}

.Sidebar_sideIcon__hTMdP {
  font-size: 18px;
  margin-right: 8px;
  position: relative;
  top: 2px;
  /* color: #a2a2a2; */
  color: darkgray !important;
  /* color: white; */
}

.Topbar_navbar__2jg9Y {
  position: fixed;
  width: 100%;
  height: 60px;
  background: #fff;
  display: flex !important;
  align-items: center;
  box-shadow: 0 0.052083333in 11.25pt -9px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #e6e6e6;
}

.Topbar_dropdownMenu__3NoJi {
  right: 0px !important;
  left: auto !important;
  width: 500px;
  /* height: 8000px !important; */
  min-height: 250px !important;
  
}
.Topbar_dropdownMenu1__JD31S {
  right: 0px !important;
  left: auto !important;
  /* width: 500px; */
}
.Topbar_navbarNav__YvCow i {
  font-size: 25px;
}
.Topbar_teacheraccept__1JxNa {
  padding-left: 2%;
 
}
.Topbar_iconText__33tYA{
  display:flex;
  /* align-items: center; */
}

.Sidebar_mainSidebar__3FT_v {
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  background: #fff;
  overflow: hidden;
  width: 240px;
  z-index: 1030;
  box-shadow: 0 0 11px -6px rgb(0 121 210 / 65%);
}

.Sidebar_logo__1hpy4 {
  text-align: center;
}

.Sidebar_logo__1hpy4 img {
  height: 100px;
  margin: 5px auto;
  display: block;
}

.Sidebar_logo__1hpy4 span {
  font-weight: 500;
  font-size: 14px;
  margin-top: 12px;
  display: block;
}

.Sidebar_mainSidebar__3FT_v ul {
  padding-left: 15px;
  position: absolute;
  width: 100%;
  height: 100%;
  padding-left: 0px;
  top: 140px;
  background-color: #222648;
}

.Sidebar_mainSidebar__3FT_v li {
  cursor: pointer;
  padding: 2px 0px;
}

.Sidebar_mainSidebar__3FT_v .Sidebar_navLink__1A5Mn {
  font-size: 14px;
  text-decoration: none;
  color: white;
  font-weight: 500;
  padding: 10px 15px !important;
}

.Sidebar_sideIcon__Kd24F {
  font-size: 18px;
  margin-right: 8px;
  position: relative;
  top: 2px;
  /* color: #a2a2a2; */
}

.Topbar_navbar__2vLBV {
  position: fixed;
  width: 100%;
  height: 60px;
  background: #fff;
  display: flex !important;
  align-items: center;
  box-shadow: 0 0.052083333in 11.25pt -9px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid #e6e6e6;
}

.Topbar_dropdownMenu__3TjZl {
  right: 0px !important;
  left: auto !important;
}

.Topbar_navbarNav__28J97 i {
  font-size: 25px;
}

.theam-color {
  background: #4a1d75 !important;
  /* height: 100vh !important; */
  overflow-y: hidden !important;
}

.register {
  background: #fff;
  /* background: -webkit-gradient(
     linear,
     left bottom,
     right top,
     from(#fc2c77),
     to(#6c4079)
   );
   background: -webkit-linear-gradient(bottom left, #fc2c77 0%, #6c4079 100%);
   background: -moz-linear-gradient(bottom left, #fc2c77 0%, #6c4079 100%);
   background: -o-linear-gradient(bottom left, #fc2c77 0%, #6c4079 100%);
   background: linear-gradient(to top right, #fc2c77 0%, #6c4079 100%); */
  /* position: absolute; */
  right: 0px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  height: 100%;
}

.register-left {
  text-align: center;
  color: #fff;
}

.register-form .label-heading,
.login-right .label-heading {
  display: none;
}

.register-left input {
  border: none;
  border-radius: 1.5rem;
  background: #f8f9fa;
  font-weight: bold;
  color: #383d41;
  cursor: pointer;
}

.register-right {
  background: #f8f9fa;
  border-top-left-radius: 10% 50%;
  border-bottom-left-radius: 10% 50%;
  margin-top: 15px;
}

.register .register-form {
  padding: 50px 100px;
}

.btnRegister {
  border: none;
  border-radius: 1.5rem;
  padding: 8px;
  background: #0f5ef6;
  color: #fff;
  font-weight: 500;
  width: 130px;
  letter-spacing: 1px;
  font-size: 15px;
}

.register-heading {
  text-align: center;
  color: #495057;
  padding-top: 25px;
  font-weight: 600;
}

.register-form hr {
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 0px solid rgb(49 69 186 / 8%);
}

.section-row {
  border: 1px solid #ececec;
  padding: 25px 10px;
  padding-bottom: 0px;
  background: #fff;
}

.login {
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(to top right, #0079d2 0%, #0079d2 100%);
}

.login-right {
  background: #fff;
  position: absolute !important;
  height: 100%;
  padding: 0px;
  right: 0px;
}

.welcome-elearn {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 60px;
  font-weight: 700;
  font-size: 26px;
}

.welcome-elearn span {
  color: #0f5ef6;
  font-weight: 900;
}

.login hr {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  border: 0;
  border-top: 1px solid rgb(49 69 186 / 8%);
  width: 60%;
}

.lw {
  padding: 8px 10px;
  color: #000;
  font-size: 17px;
  width: 230px;
  margin: 0 auto;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-bottom: 15px;
  cursor: pointer;
  font-weight: 500;
}

.lw img {
  height: 20px;
  margin-right: 10px;
}

.loginError {
  color: red;
  text-align: center;
  margin-bottom: 20px;
  margin-top: -18px;
}
.remove1 {
  border-radius: 3px;
  border: 1px solid;
}
.register1 {
  color: white;
}
.register1:hover {
  color: white;
  text-decoration: none;
}

.password {
  position: relative !important;
}

#eye_icon {
  position: absolute;
  top: 8px;
  right: 18px;
  cursor: pointer;
}

.errorMsg{
  position: absolute;
  top: 37px;
}
#eye_icon1 {
  position: absolute;
  top: 235px;
  left: 1850px;
}
@media only screen and (max-width: 1280px) {
  #eye_icon1 {
    left: 1050px;
  }
}
@media only screen and (max-width: 1350px) {
  #eye_icon1 {
    left: 1300px;
  }
}
@media only screen and (max-width: 1400px) {
  #eye_icon1 {
    left: 1190px;
  }
}
@media only screen and (max-width: 1450px) {
  #eye_icon1 {
    left: 1240px;
  }
}
@media only screen and (max-width: 1750px) {
  #eye_icon1 {
    left: 1330px;
  }
}
@media only screen and (max-width: 1850px) {
  #eye_icon1 {
    left: 1330px;
  }
}
@media only screen and (max-width: 2000px) {
  #eye_icon1 {
    left: 1220px;
  }
}
@media only screen and (max-width: 2100px) {
  #eye_icon1 {
    left: 1820px;
  }
}

.Place-Select {
  color: gray;
  width: 100%;
  height: 40px;
}

.login-left {
  display: flex;
  justify-content: center;
  /* width: 100%; */
  padding-top: 130px;
  *padding-right: 940px;
}


body{
  margin: 0;
  padding: 0;
}
.verify_card__RhWqf {
  width: 40%;
  text-align: center;
}
.verify_cardImg__1G4UH {
  height: 100;
}
.verify_image__10Ay5 {
  margin: "23px auto";

  display: "block";
}
.verify_cardContainer__3A3XF {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* height: 200vh; */
  padding-top: 14%;

}
.verify_emailcardbody__l5Jdh{
  text-align: center !important;
  margin-top: 10px !important;
}
.verify_verifyemail__1HTvq{
  background-color: #E0F6F6 !important;
  color: #1DB9AA !important;
}


.main_container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    text-align: center;
}
.main_container .msg{
    font-size: 24px;
    font-weight: 400;
}
body {
  /* before */
  /* font-family: "Roboto", sans-serif; */
  /* after */
  font-family: "Lato";
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.3px;
}
.btn-primary {
  background: #c5f9d5 !important;
  color: #363636 !important;
}
#containerform {
  margin-top: 20px;
  border: 1px solid black;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 6px;
}
.uttam {
  width: 100%;
}

.row {
  margin: 0px;
}

.pad-0 {
  padding: 0px;
}

.mb-35 {
  margin-bottom: 25px;
}

p {
  margin-bottom: 0px;
}

a {
  color: #0079d2;
}

.btn {
  /* color: #fff !important; */

  background: #222648;
  /* text-transform: uppercase; */
  word-wrap: break-word;
  white-space: normal;
  cursor: pointer;
  border: 0;
  border-radius: 3px;
  /* box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%); */
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  /* padding: 0.6rem 1.5rem; */
  font-size: 0.81rem;
  line-height: 1.5;
  font-weight: 700;
  /* width: 203px; */
  /*  height: 53px; */
}
tbody .view_icon i {
  padding-right: 0.2rem;
  font-size: 10px;
}
/* tbody td{
  font-weight: 600;
} */
.btn-right {
  text-transform: uppercase;
  margin-left: auto !important;
  margin-right: 15px;
  display: block;
  font-size: large;
}
@media screen and (min-width: 412px) {
  .btn-right {
    text-transform: uppercase;
    margin-left: auto !important;
    margin-right: 110px;
    display: block;
    font-size: large;
    margin-top: 12px;
  }
}
@media screen and (min-width: 375px) {
  .btn-right {
    text-transform: uppercase;
    margin-left: auto !important;
    margin-right: 50px;
    display: block;
    font-size: large;
    /* margin-top: 12px; */
  }
}

.btn-sm {
  padding: 0.3rem 0.85rem;
  font-size: 0.75rem;
  line-height: 1.5;
  letter-spacing: 0.4px;
}

.btn-success {
  color: #4a1e75;
  background-color: #ebd8fe;
  border-color: #ebd8fe;
}
.btn-danger {
  color: #ff3044;
  background-color: #ffced3;
  border-color: #ffced3;
}
.btn-success:hover {
  background-color: #e0c4fc;
  color: #4a1e75;
}

.btn-info {
  color: #fff;
  background-color: #222648;
  border-color: #222648;
}
.btn-danger:hover {
  color: #ff3044 !important;
  background-color: #fcbec4 !important;
  border-color: #fcbec4 !important;
}
.btn-info:hover {
  color: #222648;
  background-color: #fff;
  border-color: #fff;
}

.btn-primary {
  color: #fff;
  background-color: #0079d2;
  border-color: #0079d2;
}

.form-group {
  margin-bottom: 1.8rem;
}

.form-control {
  font-size: 15px !important;
  height: 38px;
  border-radius: 6px;
  width: 100% !important;
}

.formSelect {
  font-size: 15px !important;
  height: 38px;
  border-radius: 6px;
}

.label-heading {
  margin-bottom: 0.2rem;
  margin-top: 1px;
  font-size: 14px;
  font-weight: 500;
  color: #545454;
}

.content-wrapper {
  transition: margin 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, margin 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, margin 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  margin-left: 240px;
  z-index: 820;
  margin-top: 60px;
  /*  padding: 20px 5px; */
  min-height: 500px;
  background: #f7f7f7;
  /* background: #f6f7fb; */
  overflow: hidden;
}

.text-heading {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin-left: 15px;
  margin-bottom: 10px;
  /* margin-top: 20px; */
}
.text-heading1 {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin-left: 7px;
  margin-bottom: 10px;
  /* margin-top: 20px; */
}

.viewMore {
  float: right;
  margin-left: auto !important;
  margin-right: 15px;
  margin-bottom: 0px;
  cursor: pointer;
  position: relative;
  font-weight: 500;
}

/* .viewMore a {
   color: #0079d2; 
} */

.viewMore i {
  font-size: 18px;
  position: relative;
  top: 1px;
}
#buttonItem {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 25px;
  margin-left: -22px;
}
/* .tabBotton {
  background: #fff !important;
  color: #222648 !important;
  border: 1px solid #222648 !important;
  border-radius: 3px !important;
  cursor: pointer !important;
  height: 20px !important;
} */

.tabItem {
  /* float: left; */
  /* background: #fff; */
  color: #222648;
  /* border: 1px solid #222648; */
  /* padding: 7px 10px; */
  /* margin-right: 80px; */
  /* border-radius: 3px; */
  /* font-weight: 300; */
  /* cursor: pointer; */
  /* height: 38px; */

  float: left;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  border: 1px solid #222648;
  padding: 6px 20px;
  margin-right: 10px;
  border-radius: 30px !important;
  cursor: pointer;
  /* box-sizing: border-box; */
  width: auto;
  height: 40px;
  /* height: 53px; */
  /* left: 34px;
  top: 198px; */
  background: #4a1e75;
  /* border: 1px solid #ffffff; */
  /* border-radius: 12px; */
}
.query_top {
  background-color: #0079d2;
}
.tabItemActive {
  background: #f1e3fe;
  color: #4a1e75;

  border-style: double;
  border-color: #4a1e75;
}

.error {
  color: red;
  font-size: 13px;
  margin-bottom: -20px;
}

.nav-link.active {
  /* background-color: #e3f3ff; */
  background: rgb(0 121 210 / 16%);
  color: #222648;
}

/* .nav-link:hover {
  background: rgb(0 121 210 / 16%);
  color: #888;
} */

/* .nav-link.active i {
  color: #222648;
} */

.event__ACCEPTED {
  color: #fff;
  /* font-weight: bold; */
}

.event__REJECTED {
  color: #fff;
  /* font-weight: bold; */
}

.event__PENDING {
  color: #fff;
  /* font-weight: bold; */
}

.color-status span {
  height: 40px;
  display: inline-block;
  font-weight: bold;
  margin-right: 20px;
}

.color-status span.color-pending {
  color: #03a9f4;
}

.color-status span.color-accepted {
  color: #4caf50;
}

.color-status span.color-rejected {
  color: red;
}

.rbc-event-label {
  display: none !important;
}

/* .rbc-event {
  padding: 4px 3px !important;
  background-color: #eab731 !important;
  border: 1px solid #eab731 !important;
  border-radius: 0px;
} */

.rbc-day-slot .rbc-events-container {
  margin-right: 0px !important;
}

/* 
.event__ACCEPTED,
.event__REJECTED,
.event__PENDING {
  color: #fff;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  letter-spacing: 0.5px;
}

.color-status span {
  height: 40px;
  display: inline-block;
  font-weight: 600;
  margin-right: 15px;
}

.color-status span.color-pending {
  color: #03a9f4;
}

.color-status span.color-accepted {
  color: #4caf50;
}

.color-status span.color-rejected {
  color: #f44336;
}

.rbc-event-label {
  display: none !important;
}

.rbc-event {
  padding: 4px 3px !important;
} */

.exists-acc {
  text-align: center;
  margin-top: 30px;
}
.font-size {
  font-size: 30px;
}
.react-datepicker__time-container {
  width: 150px;
}
.copy_btn {
  position: relative;
  display: inline-block;
}
.copy_menu {
  position: absolute;
  top: 23px;
  left: -200px;
  background-color: white;
  visibility: hidden;
  z-index: 2;
  width: 355px;
  height: 60px;
  box-shadow: 0px 0px 20px rgb(188, 186, 186);
  border-radius: 10px;
}
.threedotbtn {
  z-index: -1;
  margin: 0px 0px 0px 8px;
  font-size: 20px;
  cursor: pointer;
}

.threedotbtn:hover {
  box-shadow: 0px 0px 10px rgb(163, 162, 162);
}
.threedotbtn:hover ~ .copy_menu {
  visibility: visible;
}
.copy_menu li {
  list-style: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  line-height: 30px;
  font-size: 15px;
  cursor: pointer;
}
.copy_menu:hover {
  visibility: visible;
}
.copy_menu li:hover {
  color: #0079d2;
}

/*==================calender new added css============== */
.card .card-body .color_status_div {
  border: 2 px solid red !important;
}
.cal_con .active {
  color: red !important;
}

/* ========================query new added style============== */
.query_head {
  background: #f1e3fe;
  border-radius: 10px;
}

.pointer {
  cursor: pointer !important;
}

.cancel {
  background: #ffccd1;
  color: #ff1b49;
  line-height: 20px;
  border: none;
  padding: 6px 10px;
  font-weight: 700;
  border-radius: 3px;
  margin-top: 3px;
}

.form-heading {
  color: #501a74;
  font-size: 24px;
  font-weight: 600;
}

.scroll_container {
  height: 90vh;
  overflow-y: scroll;
  border-top: 1px solid lightgray;
}
/* width */
.scroll_container::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.scroll_container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.scroll_container::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.scroll_container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.view_btn {
  background: #b4fbd7;
  padding: 4px 7px;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 2px;
}

/* .active {
  background: #501a74 ;
} */
.active {
  background-color: lightgray !important;
  padding: 2px;
  /* padding-bottom: 1px; */
}

.toggle_dot {
  width: 10px !important;
  height: 10px !important;
  border-radius: 100% !important;
  background: #f1e3fe;
  margin-right: 10px;
}

.customH {
  height: 100% !important;
}
.customH img {
  width: 100%;
  height: auto;
}

.main_box {
  /* border: 1px solid red; */
  width: 100%;
  /* border: 2px solid red; */
  margin: 0% 2%;
  position: relative;
  /* height: 200px; */
}
.img_box {
  width: 80px;
  height: 80px;
  /* position: absolute; */
  /* padding: 20px; */
  /* border-radius: 100%; */
  /* background: blue; */
  /* top: 18%; */
  /* left: 36%; */
}
.img_box1 {
  width: 80px;
  height: 80px;

  /* position: absolute; */
  /* padding: 20px; */
  /* border-radius: 100%; */
  /* background: blue; */
  /* top: 18%; */
  /* left: 36%; */
}

.dropdown-menu {
  height: 103px !important;
  overflow-y: auto !important;
}

/* .navbar-nav >.nav-item >.nav-link.active> li { 
    background-color: red ; 
} */

.test {
  /* background-color: #888 !important; */
  text-decoration: none !important;
  color: #222 !important;
}
/* li{
  text-decoration: none;
} */
/* .nav-item {
  display: flex;
  justify-content: space-between;
} */

