.date {
  background-color: #4A1E75;
  border-radius: 5px;
  padding: 3px 20px;
  color: rgb(255, 255, 255);
}

.head {
  font-size: 18px;
}
.amount{
  font-size: 12px !important;
  font-weight: 700;
}
.num {
  font-size: 23px;
  text-align: center;
}
.click{
  margin: 3px;
}
.join{
  background: #EBD8FE !important;
  color:  #4A1E75 !important;
  padding: 5px 30px !important;
}
.join:hover{
  background-color: #e0c4fc !important;
}
.cardheading{
  padding: 17px 17px;
  background: #F1E3FE;
  border-radius: 10px;
  color:#20639B;
}