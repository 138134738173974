.theam-color {
  background: #4a1d75 !important;
  /* height: 100vh !important; */
  overflow-y: hidden !important;
}

.register {
  background: #fff;
  /* background: -webkit-gradient(
     linear,
     left bottom,
     right top,
     from(#fc2c77),
     to(#6c4079)
   );
   background: -webkit-linear-gradient(bottom left, #fc2c77 0%, #6c4079 100%);
   background: -moz-linear-gradient(bottom left, #fc2c77 0%, #6c4079 100%);
   background: -o-linear-gradient(bottom left, #fc2c77 0%, #6c4079 100%);
   background: linear-gradient(to top right, #fc2c77 0%, #6c4079 100%); */
  /* position: absolute; */
  right: 0px;
  left: 0px;
  top: 0px;
  bottom: 0px;
  height: 100%;
}

.register-left {
  text-align: center;
  color: #fff;
}

.register-form .label-heading,
.login-right .label-heading {
  display: none;
}

.register-left input {
  border: none;
  border-radius: 1.5rem;
  background: #f8f9fa;
  font-weight: bold;
  color: #383d41;
  cursor: pointer;
}

.register-right {
  background: #f8f9fa;
  border-top-left-radius: 10% 50%;
  border-bottom-left-radius: 10% 50%;
  margin-top: 15px;
}

.register .register-form {
  padding: 50px 100px;
}

.btnRegister {
  border: none;
  border-radius: 1.5rem;
  padding: 8px;
  background: #0f5ef6;
  color: #fff;
  font-weight: 500;
  width: 130px;
  letter-spacing: 1px;
  font-size: 15px;
}

.register-heading {
  text-align: center;
  color: #495057;
  padding-top: 25px;
  font-weight: 600;
}

.register-form hr {
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 0px solid rgb(49 69 186 / 8%);
}

.section-row {
  border: 1px solid #ececec;
  padding: 25px 10px;
  padding-bottom: 0px;
  background: #fff;
}

.login {
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(to top right, #0079d2 0%, #0079d2 100%);
}

.login-right {
  background: #fff;
  position: absolute !important;
  height: 100%;
  padding: 0px;
  right: 0px;
}

.welcome-elearn {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 60px;
  font-weight: 700;
  font-size: 26px;
}

.welcome-elearn span {
  color: #0f5ef6;
  font-weight: 900;
}

.login hr {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  border: 0;
  border-top: 1px solid rgb(49 69 186 / 8%);
  width: 60%;
}

.lw {
  padding: 8px 10px;
  color: #000;
  font-size: 17px;
  width: 230px;
  margin: 0 auto;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-bottom: 15px;
  cursor: pointer;
  font-weight: 500;
}

.lw img {
  height: 20px;
  margin-right: 10px;
}

.loginError {
  color: red;
  text-align: center;
  margin-bottom: 20px;
  margin-top: -18px;
}
.remove1 {
  border-radius: 3px;
  border: 1px solid;
}
.register1 {
  color: white;
}
.register1:hover {
  color: white;
  text-decoration: none;
}

.password {
  position: relative !important;
}

#eye_icon {
  position: absolute;
  top: 8px;
  right: 18px;
  cursor: pointer;
}

.errorMsg{
  position: absolute;
  top: 37px;
}
#eye_icon1 {
  position: absolute;
  top: 235px;
  left: 1850px;
}
@media only screen and (max-width: 1280px) {
  #eye_icon1 {
    left: 1050px;
  }
}
@media only screen and (max-width: 1350px) {
  #eye_icon1 {
    left: 1300px;
  }
}
@media only screen and (max-width: 1400px) {
  #eye_icon1 {
    left: 1190px;
  }
}
@media only screen and (max-width: 1450px) {
  #eye_icon1 {
    left: 1240px;
  }
}
@media only screen and (max-width: 1750px) {
  #eye_icon1 {
    left: 1330px;
  }
}
@media only screen and (max-width: 1850px) {
  #eye_icon1 {
    left: 1330px;
  }
}
@media only screen and (max-width: 2000px) {
  #eye_icon1 {
    left: 1220px;
  }
}
@media only screen and (max-width: 2100px) {
  #eye_icon1 {
    left: 1820px;
  }
}

.Place-Select {
  color: gray;
  width: 100%;
  height: 40px;
}

.login-left {
  display: flex;
  justify-content: center;
  /* width: 100%; */
  padding-top: 130px;
  *padding-right: 940px;
}


body{
  margin: 0;
  padding: 0;
}