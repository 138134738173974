body{
    
    padding: 0 !important;
    margin: 0 !important;
}
.MainDiv{
    background: #F1E3FE !important;
    height: 160vh !important;
    width: 100%;
    margin-bottom: 40px;
}
.heading{
     width: 100% !important;
  padding: 10px 20px;
  background: #4A1E75;
}
.assessmentHeading{
    margin-top: 3%;
    margin-left: 2%;
}
.mainContainer{
    /* border:2px solid red */
}
.firstRow{
    display: flex;
    justify-content: center;
    margin-top: 60px;
}
.secondRow{
     display: flex;
    justify-content: center;
    height: 60vh;
    margin-top: 20px;
}
.imgaICon{
    /* font-size: 10px !important; */
    width: 50px;
}
/* .imgaICon4{
    font-size: 10px !important;
   
    height: 100px;

} */
.vector1{
    background-color: #AB94E2;
   padding: 40px 60px;
    border-radius: 8px;
    margin-right: 20px;
    width:25vw
    /* display: flex; */
    /* justify-content: center;
    align-items: center;
    text-align: center; */
}
.img1{
    background-color: #A9DDDD;
    border-radius: 50%;
    padding: 10px;
     width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    /* border: 2px solid red; */
     /* margin-left: 50px; */
     margin: 0 auto;

}
.vector2{
    background-color: #AB94E2;
    /* padding: 40px; */
    border-radius: 8px;
    margin-left: 20px;
    padding: 40px 60px;
     width:25vw
}
.img2{
    justify-content: center;
    background-color: #FBB0B8;
    border-radius: 50%;
    padding: 10px;
      width: 100px;
    height: 100px;
      margin: 0 auto;
}
.vector3{
    background-color: #AB94E2;
    padding: 40px 63px;
    border-radius: 8px;
    height: 35vh;
    margin-right: 20px;
    width: 25vw;
}
.img3{
    background-color: #FFD59C;
    border-radius: 50%;
     width: 100px;
    height: 100px;
    padding: 10px;
      margin: 0 auto;
   
}
.vector4{
    background-color: #AB94E2;
     padding: 40px 66px;
    border-radius: 8px;
    height: 35vh;
    margin-left: 20px;
     width: 25vw;
}
.img4{
      background-color: #A9DBFF;
      border-radius: 50%;
    width: 100px;
    height: 100px;
    padding: 10px;
      margin: 0 auto;
      
}
.LastBox{
    display: flex;
    justify-content: center;
}
.testDuration{
    display: flex;
    justify-content: space-between;
    width: 55vw;
    background-color: #fff;
    border-radius: 8px;
    padding: 5px;
}