#heading {
  color: rgb(79, 157, 202);
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.head {
  font-size: 65px;
}

.topBg{
  background: url('../../assets/Images/CurriculamPage.png');
  height: 551px;
}

#head-content {
  font-size: x-large;
  max-width: 550px;
  margin: auto;
  color: rgb(83, 83, 83);
  padding: 30px 0 !important;
}

.main {
  display: grid;
  grid-template-columns: auto auto auto auto;
  -ms-grid-column-align: center;
  *margin-top: 3%;
}

.outer {
  width: 20vw;
}

#blocks {
  display: flex;
  margin: auto;
  margin-top: 22%;
}

.block-heading {
  font-size: 20px !important;
  line-height: 24px !important;
  font-weight: 600 !important;
}

.info {
  line-height: 19px !important;
  font-size: 16px !important;
}

.amount {
  font-size: 32px !important;
  line-height: 39px !important;
  font-weight: 700 !important;
  color: #20639B !important;
  margin-top: 25px;
}

.btn2{
  margin-top: 25px;
  padding: 5px 13px;
  background: #F1E3FE;
  border: 1px solid #4A1E75;
  border-radius: 30px;
  margin-bottom: 20px;
  font-size: 20px;
  color: #464646 !important;
  font-weight: 500;
}

.btn1{
  margin-top: 25px;
  padding: 5px 13px;
  background: #4A1E75;
  border: 1px solid #4A1E75;
  border-radius: 30px;
  margin-bottom: 20px;
  font-size: 20px;
  color: white !important;
  font-weight: 500;
}

.block {
  width: 35vw;
  *height: 30vh;
  padding: 25px 10px;
  background-color: #C5F9D5;
  border-radius: 8px;
}



#button {
  width: 100%;
  color: red;
  /* margin: auto; */
}

@media only screen and (max-width: 1064px) {
  .main {
    display: grid;
    grid-template-columns: auto auto auto ;
  
  }
  .outer {
    width: 25vw;
   
  }
}

@media only screen and (max-width: 912px) {
  .outer {
    width: 30vw;
   
  }

  .block-heading {
    font-size: 20px !important;
    line-height: 24px !important;
    font-weight: 600 !important;
  }

  .info {
    line-height: 19px !important;
    font-size: 16px !important;
  }

  .amount {
    font-size: 32px !important;
    line-height: 39px !important;
    font-weight: 700 !important;
  }
}

@media only screen and (max-width: 836px) {
  .main {
    display: grid;
    grid-template-columns: auto auto ;
    *border: 1px solid red;
    margin: 0 auto;
   
  }
  .outer {
    width: 40vw;
    *border: 1px solid red;
  }
  .block {
    width: 40vw;
  }
}

@media only screen and (max-width: 605px) {
  .main {
   display: flex;
   flex-direction: column; 
  }
  .outer {
    width: 75vw;
    *border: 1px solid red;
  }
  .block {
    width: 75vw;
  }
}

.box_img {
  /* margin: 0 auto !important; */
  height: 60px;
  width: 60px;
}

