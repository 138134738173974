#combo-box-demo {
  position: absolute;
  width: 81%;
  overflow: hidden;
}
.input-group-text{
   width: 30% !important;
  border-radius: 40px;
}
.questionHeader{
  display: flex;
  justify-content: space-between;
  width: 98% !important;
  margin-bottom: 15px !important;
  align-items: center;
}
@media only screen and (max-width: 1280px){
  .questionHeader{
     width: 85% !important;
  }
}
.table_container table thead tr th{
  color: #20639B !important;
  background-color: #F1E3FE !important;
  padding-bottom:16px !important;
}

.relative{
  position: relative !important;
  *border: 1px solid red;
  width: auto !important;
}

.drop_icon{
  position: absolute !important;
  top: 32px;
  right: 10px;
  
}