.courseHeader{
    display: flex;
  justify-content: space-between;
  width: 98% !important;
  margin-bottom: 15px !important;
  align-items: center;
}
.input-group-text{
    width: 30% !important;
  border-radius: 40px !important;
}
@media only screen and (max-width: 1280px){
  .courseHeader{
    width: 85% !important;
  }
}
@import url("//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css");
/* body { margin: 30px; } */
.search {
  position: relative;
  color: #aaa;
  font-size: 5px;
}

.search {display: inline-block;}

.search input {
  width: 230px;
  height: 35px;

  background: #fcfcfc;
  border: 1px solid #aaa;
  border-radius: 5px;
  box-shadow: 0 0 3px #ccc, 0 10px 15px #ebebeb inset;
}

.search input { text-indent: 32px;}
.search .fa-search { 
  position: absolute;
  top: 10px;
  left: 10px;
}


.table_container table thead tr th{
  color: #20639B !important;
  background-color: #F1E3FE !important;
  padding-bottom:16px !important;
}
.table_container1 table thead tr th{
background-color: #F1E3FE !important;
padding-bottom:16px !important;
}