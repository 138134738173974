.admindashome {
  margin-left: 60px;
}
.main {
  display: flex;
  justify-content: space-around;
}
.card {
  margin: 25px;
  padding: 20px;
  width: 272px;
  height: 142px;

  border-radius: 8px;
  background: #a9dddd;
}
.curri {
  display: flex;
  justify-content: space-between;
}
.card2 {
  margin: 25px;
  padding: 20px;

  width: 272px;
  height: 142px;
  border-radius: 8px;
  background: #fbb0b8;
}
.assess {
  display: flex;
  justify-content: space-between;
}
.card3 {
  margin: 25px;
  width: 272px;
  height: 142px;
  padding: 20px;
  border-radius: 8px;
  background: #ccbfeb;
}
.sessio {
  display: flex;
  justify-content: space-between;
}
.submain {
  display: flex;
  justify-content: space-around;
  /* padding: 0 25%; */
  padding-top: 5%;
  padding-left: 19%;
  padding-right: 15%;
}
.card4 {
  margin: 25px;
  width: 272px;
  height: 142px;
  padding: 20px;
  border-radius: 8px;
  background: #ffd59c;
}
.student {
  display: flex;
  justify-content: space-between;
}
.card5 {
  margin: 25px;
  width: 272px;
  height: 142px;
  padding: 20px;
  border-radius: 8px;
  background: #a9dbff;
}
.teacher {
  display: flex;
  justify-content: space-between;
}
