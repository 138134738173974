.queryDiv p {
  display: inline-block;
  font-size: 14px;
  color: black;
  margin-bottom: 0;
  line-height: 27px;
}
.selectstyle{
  border-radius: 40px;
}
.raisequerymain{
  padding-top: 40px;
}

.dashimg {
  background-image: url("../../../assets/Banners/group3.png");
  /* height: 70vh; */
  overflow: hidden;
  padding-top: 340px;
  padding-bottom: 210px;
    background-repeat: no-repeat;
  background-position: center;
    background-size: cover;
  /* padding-bottom: 300px; */
}
.butonstyle{
  /* padding: 0 20px; */
  display: flex;
  justify-content: space-between;
  width: 40%;
  
}
.buttonTab{
  width: 200px !important;
  text-align: center;
  /* border-radius: 40px; */
  border-radius: 30px !important;
  padding-top: 8px !important;
  /* font-size: 30px; */
  /* border: 2px solid green; */
}
.queryTypeButton{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.hr {
  margin-top: 15px;
  margin-bottom: 10px;
  clear: both;
  /* width:100%; */
}
/* .cardinput{
  width: 100%;
} */

.curriculum{
   margin-left: 10px;
}
.subject{
   margin-left: 10px;
}
.topic{
  margin-left: 10px;
}
.chapter {
  float: left;
  margin-right: 10px;
  margin-top: 8px;
  font-size: 16px;
  /* line-height: 20px; */
   margin-left: 10px;
}

.main {
  width: 94vw;
  border-radius: 6px;
  /* border: 1px solid black; */
  margin-top: 25px;




}

.queryFeatures {
  /* background: #e9edf6; */
  background: #e9eaec;
  margin-top: 10px;
  padding: 10px;
  overflow: hidden;
}

.queryFeatures span {
  cursor: pointer;
  padding: 4px 10px;
  margin-left: 10px;
  border-radius: 2px;
  float: right;
  color: #fff;
  font-size: 13px;
  letter-spacing: 0.5px;
}

.queryFeatures .replay {
  background: #AA96DB;
}

.queryFeatures .resolve {
  background: #66bb6a;
}

.queryFeatures .feedback {
  background: #42a5f5;
}

.raiseQueryDiv select {
  height: 42px;
}

.noReplay {
  color: #000 !important;
  font-size: 14px !important;
  font-weight: 500;
}

.studentReview {
  background: #e6f3fd;
  padding: 10px;
  border-radius: 5px;
  width: 70%;
  margin: 10px 14px;
}

.teacherReview {
  background: #fff2e8;
  padding: 10px;
  border-radius: 5px;
  width: 70%;
  margin: 10px 0px;
}

.messagesDiv {
  max-height: 250px;
  overflow-y: auto;
}

/* width */
.queryDiv ::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.queryDiv ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px #ccc;
  border-radius: 10px;
}

/* Handle */
.queryDiv ::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 10px;
}

/* Handle on hover */
.queryDiv ::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

.open {
  display: block;
}

.close {
  display: none;
}

.cardDiv2 {
  background-color: #ecf3ff;
  Width: 1170px;
  Height: 244px;
  Top: 437px;
  Left: 67px;
  Radius: 6px;
  margin: 15px;
  border-radius: 6px;
}

.cardDiv3 {
  background-color: #ECF3FF;
  height: 190px;
  border-radius: 6px;
}








@media screen and (min-width: 280px) {
  .cardDiv3 {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 19%;
  }
}
@media screen and (min-width: 280px) {

  .curriculum,
  .subject,
  .topic,
  .chapter {
    float: left;
    margin-right: 3px;
    margin-top: 8px;
    font-size: 9px;
    /* line-height: 20px; */
  }
}


@media screen and (min-width: 360px) {
  .cardDiv3 {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 25%;
  }
}

@media screen and (min-width: 360px) {

  .curriculum,
  .subject,
  .topic,
  .chapter {
    float: left;
    margin-right: 1px;
    margin-top: 8px;
    font-size: 12px;
    /* line-height: 20px; */
  }
}

@media screen and (min-width: 375px) {
  .cardDiv3 {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 26%;
  }
}

@media screen and (min-width: 375px) {

  .curriculum,
  .subject,
  .topic,
  .chapter {
    float: left;
    margin-right: 1px;
    margin-top: 8px;
    font-size: 13px;
    /* line-height: 20px; */
  }
}
@media screen and (min-width: 412px) {
  .cardDiv3 {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 29%;
  }
}
@media screen and (min-width: 412px) {

  .curriculum,
  .subject,
  .topic,
  .chapter {
    float: left;
    margin-right: -1px;
    margin-top: 8px;
    font-size: 14px;
    /* line-height: 20px; */
  }
} 
@media screen and (min-width: 540px) {
  .cardDiv3 {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 40%;
  }
}
@media screen and (min-width: 768px) {
  .cardDiv3 {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 58%;
  }
}

@media screen and (min-width: 820px) {
  .cardDiv3 {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 62%;
  }
}

@media screen and (min-width: 1024px) {
  .cardDiv3 {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 79%;
  }
}

@media screen and (min-width: 912px) {
  .cardDiv3 {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 70%;
  }
}

@media screen and (min-width: 1280px) {
  .cardDiv3 {
    background-color: #ECF3FF;
    height: 190px;
    border-radius: 6px;
    width: 100%;
  }
}