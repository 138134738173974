.dashimg {
  height: 60vh;
  overflow: hidden;
}
.timeWidth {
  width: 150px !important;
}
.react-datepicker__time-container {
  width: 150px !important;
}
.relative{
  position: relative !important;
  *border: 1px solid red;
  width: auto !important;
}

.drop_icon{
  position: absolute !important;
  top: 7px;
  right: 130px;
  
}

@media screen and (max-width: 991px) {
  .drop_icon{
    position: absolute !important;
    top: 7px;
    right: 40px;
    
  }
}

@media screen and (max-width: 575px) {
  .drop_icon{
    position: absolute !important;
    top: 7px;
    right: 60px;
    
  }
}

@media screen and (max-width: 525px) {
  .drop_icon{
    position: absolute !important;
    top: 7px;
    right: 40px;
    
  }
}

@media screen and (max-width: 412px) {
  .drop_icon{
    position: absolute !important;
    top: 7px;
    right: 20px;
    
  }
}