.topCardLeft {
  float: left;
}
.viewstyle {
  border-radius: 40px;
  border: 2px solid #4a1d75;
  padding: 5px;
  background-color: #4a1d75 !important;
  /* margin-left: 2px; */
  color: #fff !important;
  text-decoration: none !important;
  /* width: 20vw; */
}

.dashimg {
  /* width: 30vh;
  height: 100vh;
  overflow: hidden; */
  background-image: url("../../..//assets/Images/group3.png");
  background-size: cover;
  color: white;
  text-align: center;
  /* width:px; */
  background-repeat: no-repeat;
  background-position: center;
  /* width: 100%; */
}
.dashtext {
  /* padding: 300px 0; */
  /* padding-top: 0px; */
  padding-top: 200px;
  padding-bottom: 350px;
}
@media only screen and (max-width: 1300px) {
  .dashtext {
    /* padding: 20px 0!important; */
    /* padding-top: 0px; */
    padding-top: 148px !important;
    padding-bottom: 248px !important;
  }
  .text {
    color: #ffffff;
    font: inter;
    font-weight: 500;
    font-size: 18px !important;
    letter-spacing: 2px;
    /* color: red; */
    /* border:2px solid red!important */
    padding-top: 4px !important;
  }
  .text1 {
    color: #fff;
    font: lato;

    letter-spacing: normal;
    /* font-size: 10px!important; */
    /* border:2px solid red!important */
  }
  /* .learnMedia{
  top: 50%;
}; */
  .learn {
    font-size: 100px !important;
    font-weight: 500;
    letter-spacing: 1px;
  }
  .in {
    font-size: 100px !important;
    color: #ffffff;
    letter-spacing: normal;
    /* font-size: 10px!important */
  }
  .new {
    font-size: 100px !important;
    color: #ffffff;
    font-family: lato;
    letter-spacing: normal;
  }
  .text2 {
    color: #fff;
    font-family: inter;
    /* letter-spacing: 15px; */
    font-size: 17px !important;
    /* border:2px solid red!important */
  }
  .course {
    color: #fff;
    font-family: inter;
    font-size: 17px !important;
  }
}
.text {
  color: #ffffff;
  font: inter;
  font-weight: 400;
  font-size: 25px;
  letter-spacing: 2px;
  /* border:2px solid green */
  /* padding-bottom: 20px; */
}
.text1 {
  color: #fff;
  font: lato;

  letter-spacing: normal;
  /* border:2px solid green */
}
.learn {
  color: #c5f9d5;
  font-size: 140px;
  font-weight: 500;
  letter-spacing: 0;
}
.in {
  font-size: 140px;
  color: #ffffff;
  letter-spacing: normal;
}
.new {
  font-size: 140px;
  color: #ffffff;
  font-family: lato;
  letter-spacing: normal;
}
.text2 {
  color: #fff;
  font-family: inter;
  font-size: 24px;
  /* border:2px solid green */
}
.course {
  color: #fff;
  font-family: inter;
  font-size: 24px;
}
.buymore {
  padding: 5px;
  font-size: 10px;
  height: 30px;
}
.topCardLeft h5 {
  font-size: 14px;
  margin-bottom: 12px;
  text-align: left;
  color: #9c9c9c;
  font-weight: 400;
}

.topCardLeft h2 {
  font-size: 17px;
  margin-bottom: 10px;
  margin-top: 0;
}

.topCardLeft span {
  font-size: 12px;
  background: #222648;
  padding: 5px 10px;
  border-radius: 10px;
  color: #fff;
  letter-spacing: 1px;
}

.topCardRight {
  float: right;
  padding: 15px 0;
  padding-bottom: 0px;
}
.carding {
  width: 18vw;
  height: auto;
  padding: 2px;
  background-color: #c5f9d5;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.assignmentDiv1 {
  padding: 1px !important;
  background-color: #aa96db !important;
  /* border-radius: 8px!important; */
  text-align: center;
  width: 18vw;
  height: 12vh;
  justify-content: center;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.sessionsDiv {
  /* padding:1px!important; */
  background-color: #ffffd3 !important;
  /* background-color: #C5F9D5!important; */
  /* border-radius:0 0 8px 8px!important; */
  text-align: center;
  width: 18vw;
  height: 25vh;
  justify-content: center;
  color: black;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.sessionbutton {
  background-color: #ab94e2 !important;
  border-radius: 40px !important;
  width: 80px !important;
  height: 30px !important;
}
.topCardRight img {
  width: 58px;
}

.assignmentDiv h5,
.queriesDiv h5 {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-bottom: 5px;
}

.assignmentDiv p {
  display: inline-block;
  font-size: 14px;
  color: #686f7a;
  margin-bottom: 0;
  margin-top: 3px;
}

.queriesDiv p {
  display: inline-block;
  font-size: 14px;
  color: #686f7a;
  margin-bottom: 0;
  line-height: 27px;
}

.hr {
  margin-top: 10px;
  margin-bottom: 10px;
}

.curriculum {
  margin-right: 10px;
  font-size: 15px;
}

.subject,
.chapter {
  float: left;
  margin-right: 10px;
  font-size: 15px;
}

.queryDiv {
  /* line-height: 28px; */
  padding: 10px !important;
  background-color: #c5f9d5 !important;
  border-radius: 8px !important;
  text-align: center;
  width: 18vw;
  /* height: 18vh; */
  margin-bottom: 20px;
  height: auto;
  /* margin-bottom: 10px; */
}

/* .queryDiv .subject {
  float: none;
  margin-right: 10px;
  font-size: 14px;
  width: 100%;
} */

/* .queryDiv .topic {
  float: none;
  margin-right: 10px;
  font-size: 14px;
} */

/* .queryDiv .chapter {
  margin-right: 10px;
  font-size: 14px;
} */

.sessionsDiv h2 {
  font-size: 15px;
  margin-bottom: 5px;
  margin-top: 0;
  color: #333;
}

.sessionsDiv p {
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 0;
}

.sessionsDiv span {
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 10px;
  color: #fff;
  letter-spacing: 1px;
}

.sessionsDiv button {
  margin-top: 12px;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.16), 0 0px 0px 0 rgba(0, 0, 0, 0.12);
  background-color: #ea9f07;
  border-color: #ea9f07;
  width: 100%;
  padding: 5px;
  border-radius: 3px;
}
.studentDash {
  display: flex;
  justify-content: space-between;
  width: 96% !important;
}
.studentDash1 {
  display: flex;
  justify-content: space-between;
  width: 95% !important;
}
.studentdashCurrentCurriculum {
  display: flex;
  justify-content: space-between;
  width: 96% !important;
  margin-bottom: 10px;
}
.row1 {
  display: flex !important;
  /* width: 100%; */
  /* justify-content: space-around; */
  /* flex-wrap: wrap; */
  /* padding: 20px; */
  /* margin-right: 60px; */
  overflow: scroll !important;
  /* -ms-overflow-style: none;
  scrollbar-width: none; */
  width: 100%;
}
.row1::-webkit-scrollbar {
  height: 0px !important;
  width: 0px !important;
  border: 1px solid #d5d5d5 !important;
}

.main_box {
  background: #c5f9d5;
  min-width: 380px;
  margin-right: 20px;
  border-radius: 30px;
}

.box_img {
  /* margin: 0 auto !important; */
  height: 60px;
  width: 60px;
}

.box_img1 {
  width: 90px;
  margin-top: -10px;
}
.box_img2 {
  width: 72px;
  margin-top: -10px;
}

.heading {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.degname {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}

.headingT {
  font-size: 25px;
  font-weight: 600;
  color: #20639b;
}

.code {
  color: #20639b;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}

.datebox {
  background: #4a1d75;
  color: white;
  border-radius: 30px;
  padding: 10px 10px;
}
