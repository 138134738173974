.dashimgstyle{
   background-image: url("../../../assets/Banners/group3.png");
     overflow: hidden;
  padding-top: 360px;
  padding-bottom: 210px;
    background-repeat: no-repeat;
  background-position: center;
    background-size: cover;
}
@media only screen and (max-width: 1280px){
  .dashimg{
   /* background-image: url("../../../assets/Banners/group3.png"); */
     /* overflow: hidden; */
 /* padding-top: 100px; */
  /* padding-bottom: 200px; */
    background-repeat: no-repeat;
  background-position: center;
    background-size: cover;
}

}
.assignmentDiv h5 {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-bottom: 5px;
  cursor: pointer;
}
/* .theam-color{
  background: #222648 !important;
} */
.dashimg {
  height: 60vh;
  overflow: hidden;
}

.assignmentDiv p {
  display: inline-block;
  font-size: 14px;
  color: #686f7a;
  margin-bottom: 0;
  margin-top: 3px;
}

.assignment-div span {
  color: #fff;
  width: 100%;
  display: block;
  padding: 1px;
  margin-top: 5px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.assignment-div span.success {
  background: green;
}

.assignment-div span.fail {
  background: red;
}

.test-summary .badge {
  float: right;
}

.testSummary {
  background: #fff;
  width: 100%;
  float: left;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #efefef;
  box-shadow: none;
  transition: all 0.2s ease-in-out;
}

.testSummary p {
  display: block;
  padding: 12px 10px;
  text-align: left;
  border-bottom: 1px dashed #efefef;
  margin: 0px;
}

.testSummary p:last-child {
  padding-bottom: 5px;
  border-bottom: 0;
}

.testSummary .badge {
  float: right;
  padding: 6px 8px;
  font-weight: 400;
  font-size: 13px;
  width: 30px;
}

.assignmentResult {
  display: block;
  background: transparent;
  margin-top: 8px;
  padding: 3px 10px;
  color: #fff;
  border-radius: 3px;
  text-align: center;
}

.assignmentPass {
  background: #4caf50;
}

.assignmentPassAvg {
  background: #ffbf00;
}

.assignmentFail {
  background: #f71b1b;
}

.assignmentPreviewDiv {
  background: #fff;
  float: left;
  width: 100%;
  border-radius: 6px;
  border: 2px solid #eee;
  padding: 15px;
  box-shadow: 0 0 11px -6px rgb(187 187 187 / 65%);
  font-size: 14px;
  overflow: hidden;
}

.assignmentPreviewDiv table th {
  border: 0px;
}

.assignmentPreviewDiv table td {
  border: 0px;
}

.assignmentPreviewDiv h2 {
  font-size: 22px;
  margin-bottom: 17px;
  font-weight: 600;
  color: #333;
  text-align: center;
}

.testBack {
  position: absolute;
  right: 0px;
}
.testBack1{
   position: absolute;
    /* right: ; */
    /* margin-left: 200px; */
}

.testStart {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.testTimerBg {
  background: #fff;
  width: 100%;
  float: left;
  padding: 0;
  border-radius: 10px;
  margin-bottom: 50px;
  border: 1px solid #efefef;
  transition: all 0.2s ease-in-out;
}

.testTimerLeft {
  width: 100%;
  float: left;
  display: flex;
  margin-bottom: 0px;
}

.testTimerLeft li {
  width: 50%;
  display: inline-block;
  padding: 30px 20px;
  border-right: 1px solid #efefef;
  text-align: center;
}

.testTimerLeft h4 {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin-bottom: 15px;
  text-align: center;
  line-height: 26px;
  height: 100px;
  width: 100px;
  display: inline-block;
  border: 2px solid #333;
  border-radius: 100%;
  padding: 35px 0;
  background: #fff;
}

.quesTitle {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  text-align: left;
  line-height: 28px;
  margin-top: 0;
  /* margin-bottom: 14px; */
}

.quesTitle span {
  font-weight: 600;
  margin-right: 10px;
  float: left;
}

.testInfo {
  /* text-align: right; */
  padding: 8px;
  box-shadow: 0 0.052083333in 11.25pt -9px rgb(0 0 0 / 10%);
  border-bottom: 1px solid #e6e6e6;
}
.testInfo1 {
  text-align: right;
  /* padding: 8px; */
  /* box-shadow: 0 0.052083333in 11.25pt -9px rgb(0 0 0 / 10%); */
  /* border-bottom: 1px solid #e6e6e6; */
}
.testInfo12 {
  text-align: right;
  padding-top: 3%;
}
.ruleAssessment{
   padding-left: 3%;
   padding-top: 2%;
}
.startAssessment{
  padding-left: 4%;
}
.imageAnswer{
  width: 350px;
  height:200px;
}
.testInfo h3 {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin-top: 5px;
  margin-bottom: 5px;
}

.radioCheckbox input[type="radio"] {
  margin-right: 7px;
  position: relative;
  top: 1px;
}

.quesItem {
  margin-bottom: 30px;
  box-shadow: 0px 1px 13px 0px rgb(198 198 198 / 30%);
  border: 1px solid rgba(51, 122, 183, 0.21);
  padding: 10px;
  background: #fff;
}

.backLink {
  color: #333;
  float: right;
  height: 38px;
  background: #f1f1f1;
  border: 1px solid #d0d0d0;
  border-radius: 20px;
  padding: 8px 20px;
  font-size: 14px;
  margin-top: 10px;
}

.backLink a {
  text-decoration: none;
  color: #000;
}

.testResultLeft {
  text-align: center;
}

.testResultLeft li {
  width: 150px;
  display: inline-block;
  padding: 0;
}

.resultDt {
  float: left;
  width: 100%;
  text-align: center;
}

.resultDt span {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 10px;
  text-align: center;
  line-height: 50px;
  height: 55px;
  width: 55px;
  display: inline-block;
  border-radius: 100%;
}

.resultDt p {
  font-weight: 500;
  font-size: 16px;
}

.rightAns {
  background: #01b200;
}

.wrongAns {
  background: #fb3838;
}

.totQues {
  background: #57a4ff;
}
.my-component {
  background: #fff;
}

.fullscreen-enabled .my-component {
  background: #000;
}