.card {
  width: 40%;
  text-align: center;
}
.cardImg {
  height: 100;
}
.image {
  margin: "23px auto";

  display: "block";
}
.cardContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* height: 200vh; */
  padding-top: 14%;

}
.emailcardbody{
  text-align: center !important;
  margin-top: 10px !important;
}
.verifyemail{
  background-color: #E0F6F6 !important;
  color: #1DB9AA !important;
}

