.cardDiv {
 /* before */
  /* background: rgb(255, 255, 255); */
  /* after */
  background: #F5F5F5;

  width: 100%;
  border-radius: 6px;
  border: 2px solid rgb(238, 238, 238);
  padding: 15px;
  padding-top: 30px;
  text-align: center;
  /* align-items: center;
  justify-content: center;
  display: flex;
  font-size: 15px; */
}

.center {
  font-weight: 400;
  margin-top: 3px;
}

.modalContent {
  background: transparent !important;
  border: 0px !important;
}

.modalBackdrop {
  opacity: 0.7 !important;
}
